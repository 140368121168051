var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { uuid } from '../../../../utils/helpers';
var boxShadow = {
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.15)'
};
var BasicTable = function (_a) {
    var data = _a.data, cols = _a.cols;
    var _b = useState(false), actionDropdown = _b[0], setActionDropdown = _b[1];
    var _c = useState(null), actionId = _c[0], setActionId = _c[1];
    var handleAction = function (e, id) {
        if (e.target) {
            setActionDropdown(function (prev) { return !prev; });
            setActionId(id);
        }
        else {
            setActionDropdown(false);
        }
        // console.log(e.target, 'target')
    };
    return (_jsx("div", { children: _jsx("div", __assign({ className: "flex flex-col" }, { children: _jsx("div", __assign({ className: "overflow-x-auto sm:-mx-6 lg:-mx-8" }, { children: _jsx("div", __assign({ className: "inline-block min-w-full sm:px-6 lg:px-8" }, { children: _jsx("div", __assign({ className: "overflow-x-auto" }, { children: _jsxs("table", __assign({ className: "min-w-full border-separate border-spacing-y-2" }, { children: [_jsx("thead", __assign({ className: "bg-white dark:bg-[#27282D]" }, { children: _jsx("tr", __assign({ className: '' }, { children: cols.map(function (element) { return (_jsx("th", __assign({ scope: "col", className: "first:rounded-l-xl last:rounded-r-xl text-xs font-bold text-[#AEB0CF] px-6 py-4 text-left dark:text-[#6B6C7E]" }, { children: element.title }), uuid())); }) })) })), _jsx("tbody", __assign({ className: 'bg-white dark:bg-[#27282D]' }, { children: data.map(function (item) {
                                        var _a;
                                        return (_jsxs("tr", __assign({ className: "" }, { children: [_jsx("td", __assign({ className: "first:rounded-l-xl px-6 py-4 whitespace-nowrap text-sm text-[#27282D] dark:text-white" }, { children: item === null || item === void 0 ? void 0 : item.id })), _jsx("td", __assign({ className: " px-6 py-4 whitespace-nowrap text-sm text-[#27282D] dark:text-white" }, { children: _jsxs("div", __assign({ className: 'w-[72px] h-[72px] rounded-lg relative bg-white dark:bg-[#27282D]', style: boxShadow }, { children: [_jsx("div", __assign({ className: 'absolute top-[40%] left-[15%]' }, { children: _jsx("img", { className: 'w-[36px] h-[23px]', src: item === null || item === void 0 ? void 0 : item.image_front, alt: '' }) })), _jsx("div", __assign({ className: 'absolute top-[30%] right-[15%] drop-shadow-lg' }, { children: _jsx("img", { className: 'w-[36px] h-[23px]', src: item === null || item === void 0 ? void 0 : item.image_back, alt: '' }) }))] })) })), _jsx("td", __assign({ className: " px-6 py-4 whitespace-nowrap text-sm text-[#27282D] dark:text-white" }, { children: item.name })), _jsx("td", __assign({ className: "relative last:rounded-r-xl px-6 py-4 whitespace-nowrap text-sm text-[#27282D] dark:text-white" }, { children: (_a = item === null || item === void 0 ? void 0 : item.department) === null || _a === void 0 ? void 0 : _a.map(function (ele) {
                                                        return ele === null || ele === void 0 ? void 0 : ele.department_name;
                                                    }).join(', ') }))] }), uuid()));
                                    }) }))] })) })) })) })) })) }));
};
export default BasicTable;
