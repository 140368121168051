var _a;
import { showToastMessage } from './../../utils/helpers';
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
var initialState = {
    rank: {},
    isCreatePending: false,
    rankID: '',
    isLoading: false,
    isCreateSuccess: false,
    ranks: [],
    meta: { total: 0 },
    isUpdateSuccessfull: false,
};
export var ranks = createSlice({
    name: 'rank',
    initialState: initialState,
    reducers: {
        formReset: function () { },
        CreatePending: function (state) {
            state.isCreatePending = true;
        },
        creationFailure: function (state, action) {
            state.isLoading = false;
            state.isCreatePending = false;
            showToastMessage(action.payload, 'error');
        },
        creationSuccessful: function (state, action) {
            state.isCreatePending = false;
            state.rankID = action.payload;
            state.isCreateSuccess = true;
            showToastMessage(action.payload.data.message, 'success');
        },
        resetRankApiSuccess: function (state) {
            state.isCreateSuccess = false;
        },
        listingSuccessfull: function (state, action) {
            state.ranks = action.payload.data.data.data;
            state.meta.total = action.payload.data.data.meta.total;
            state.isLoading = false;
        },
        rankUpdateSuccessfull: function (state, action) {
            state.isUpdateSuccessfull = true;
        },
        RankListPending: function (state) {
            state.isLoading = true;
        },
    },
});
export var CreatePending = (_a = ranks.actions, _a.CreatePending), creationFailure = _a.creationFailure, creationSuccessful = _a.creationSuccessful, resetRankApiSuccess = _a.resetRankApiSuccess, listingSuccessfull = _a.listingSuccessfull, rankUpdateSuccessfull = _a.rankUpdateSuccessfull, RankListPending = _a.RankListPending;
export default ranks.reducer;
export var rankCreation = function (data) {
    return apiCallBegan({
        url: '/admin/rank',
        method: 'POST',
        data: data,
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    });
};
export var rankListing = function (limit, page, searchText) {
    return apiCallBegan({
        url: "/admin/rank?limit=".concat(limit, "&page=").concat(page, "&search_key=").concat(searchText),
        method: 'GET',
        onStart: RankListPending.type,
        onSuccess: listingSuccessfull.type,
        onError: creationFailure.type,
    });
};
export var rankUpdate = function (data, id) {
    return apiCallBegan({
        url: "/admin/rank/".concat(id),
        method: 'PUT',
        data: data,
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    });
};
export var deleteRank = function (id) {
    return apiCallBegan({
        url: "/admin/rank/".concat(id),
        method: 'DELETE',
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    });
};
