import { useState, useEffect } from "react";
var useDarkMode = function () {
    var _a = useState("light"), theme = _a[0], setTheme = _a[1];
    var colorTheme = theme === "light" ? "dark" : "light";
    useEffect(function () {
        var root = window.document.documentElement;
        root.classList.add(theme);
        root.classList.remove(colorTheme);
    }, [setTheme, colorTheme]);
    return [setTheme, colorTheme];
};
export default useDarkMode;
