var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import smallCard from '../../../../assets/images/smallCard.svg';
import ViewDetails from './ViewDetails';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCardDetails } from '../../../../features/cards/cardsSlice';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Status from '../../../common/Status/Status';
var boxShadowDark = {
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.15)'
};
var PrintInfo = function () {
    var dispatch = useDispatch();
    var id = useParams().id;
    var _a = useSelector(function (state) { return state.cards; }), cards = _a.cards, isLoading = _a.isLoading;
    var printDetails = [
        {
            name: 'CARD REQUESTED ON :',
            value: "".concat((cards === null || cards === void 0 ? void 0 : cards.card_request_at) === '' || (cards === null || cards === void 0 ? void 0 : cards.card_request_at) === null ? '--' : moment((cards === null || cards === void 0 ? void 0 : cards.card_request_at)).format('LT') !== 'Invalid date' && moment((cards === null || cards === void 0 ? void 0 : cards.card_request_at)).format('LT'), " ").concat((cards === null || cards === void 0 ? void 0 : cards.card_request_at) === '' || (cards === null || cards === void 0 ? void 0 : cards.card_request_at) === null ? '--' : moment((cards === null || cards === void 0 ? void 0 : cards.card_request_at)).format('DD/MM/YYYY') !== 'Invalid date' && moment((cards === null || cards === void 0 ? void 0 : cards.card_request_at)).format('DD/MM/YYYY'))
        },
        {
            name: 'CARD DELIVERED ON :',
            value: "".concat((cards === null || cards === void 0 ? void 0 : cards.card_received_at) === '' || (cards === null || cards === void 0 ? void 0 : cards.card_received_at) === null ? '--' : moment((cards === null || cards === void 0 ? void 0 : cards.card_received_at)).format('LT') !== 'Invalid date' && moment((cards === null || cards === void 0 ? void 0 : cards.card_received_at)).format('LT'), " ").concat((cards === null || cards === void 0 ? void 0 : cards.card_received_at) === '' || (cards === null || cards === void 0 ? void 0 : cards.card_received_at) === null ? '--' : moment((cards === null || cards === void 0 ? void 0 : cards.card_received_at)).format('DD/MM/YYYY') !== 'Invalid date' && moment((cards === null || cards === void 0 ? void 0 : cards.card_received_at)).format('DD/MM/YYYY'))
        },
        {
            name: 'CARD STATUS :',
            value: "".concat(cards === null || cards === void 0 ? void 0 : cards.card_status)
        },
    ];
    useMemo(function () {
        dispatch(fetchCardDetails(id));
    }, []);
    console.log(cards);
    return (_jsxs(_Fragment, { children: [_jsx(ViewDetails, {}), isLoading ? (_jsx("div", __assign({ className: 'w-full h-96 flex justify-center items-center' }, { children: _jsx("span", __assign({ className: 'text-3xl font-bold text-primary dark:text-white' }, { children: "Loading..." })) }))) :
                _jsxs("div", __assign({ className: 'flex flex-row items-center bg-white rounded-xl mt-10 p-6 dark:bg-[#27282D]' }, { children: [_jsxs("div", __assign({ style: boxShadowDark, className: 'w-[160px] h-[160px] rounded-lg relative bg-white dark:bg-[#27282D]' }, { children: [_jsx("div", __assign({ className: 'absolute top-[40%] left-[15%]' }, { children: _jsx("img", { src: smallCard, alt: '' }) })), _jsx("div", __assign({ className: 'absolute top-[30%] right-[15%] drop-shadow-lg' }, { children: _jsx("img", { src: smallCard, alt: '' }) }))] })), _jsx("div", __assign({ className: "flex flex-col" }, { children: printDetails.map(function (item, index) { return (_jsxs("div", __assign({ className: "grid grid-cols-2 items-center px-6 pt-3" }, { children: [_jsx("div", __assign({ className: 'py-1' }, { children: _jsx("p", __assign({ className: "text-[#27282D80] font-semibold text-xs dark:text-[#6B6C7E]" }, { children: item.name })) })), _jsx("div", __assign({ className: "pl-2 py-1" }, { children: index === 2 ?
                                            _jsx(Status, { children: item === null || item === void 0 ? void 0 : item.value }) :
                                            _jsx("p", __assign({ className: "text-[#27282D] font-semibold text-sm dark:text-white" }, { children: item.value })) }))] }))); }) }))] }))] }));
};
export default PrintInfo;
