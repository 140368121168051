var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import CustomButton from '../../../common/Button';
import Pagination from '../../../common/Pagination/Pagination';
import SearchBar from '../../../common/SearchBar';
import BasicTable from './Table';
import { fetchCardTemplates } from '../../../../features/cards/cardsSlice';
import { useDispatch, useSelector } from 'react-redux';
var col = [
    { title: 'TEMPLATE ID' },
    { title: 'CARD PREVIEW' },
    { title: 'TEMPLATE NAME' },
    { title: 'LINKED DEPARTMENT' },
];
var data = [
    {
        id: 'T1',
        name: 'GOG Card 1',
        linkedDepartment: 'Education Department'
    },
    {
        id: 'T2',
        name: 'GOG Card 2',
        linkedDepartment: 'Tourism and Transport'
    },
    {
        id: 'T3',
        name: 'GOG Card 3',
        linkedDepartment: 'Medical Department'
    },
];
var initialVal = {
    searchText: ''
};
var CardTemplates = function () {
    var dispatch = useDispatch();
    var _a = useSelector(function (state) { return state.cards; }), templatesList = _a.templatesList, isLoading = _a.isLoading, metadata = _a.metadata;
    var totalTemplates = metadata.totalTemplates, totalTempPages = metadata.totalTempPages;
    var _b = useState(1), currentPage = _b[0], setCurrentPage = _b[1];
    var _c = useState(initialVal), params = _c[0], setParams = _c[1];
    var _d = useState(10), limit = _d[0], setLimit = _d[1];
    var _e = useState(10), limitonChange = _e[0], setLimitonChange = _e[1];
    var _f = useState(false), resetBtn = _f[0], setResetBtn = _f[1];
    var _g = useState(true), disableBtn = _g[0], setDisableBtn = _g[1];
    useMemo(function () {
        dispatch(fetchCardTemplates(limit, currentPage, params.searchText));
    }, [currentPage]);
    var handleSearch = function (event) {
        var _a;
        setDisableBtn(false);
        setParams(__assign(__assign({}, params), (_a = {}, _a[event.target.name] = event.target.value, _a)));
    };
    var handleApply = function () {
        setResetBtn(true);
        setCurrentPage(1);
        dispatch(fetchCardTemplates(limit, 1, params.searchText));
    };
    var handleCancel = function () {
        setDisableBtn(true);
        setResetBtn(false);
        setParams(__assign(__assign({}, params), { searchText: '' }));
        setCurrentPage(1);
        dispatch(fetchCardTemplates(limit, 1, ''));
    };
    var onLimitChange = function (event) {
        var value = event.target.value;
        if (value && Number(value) === 0)
            return;
        var re = /^[0-9\b]+$/;
        if ((value && !re.test(value)) || value > totalTemplates) {
            return;
        }
        setLimitonChange(event.target.value);
    };
    var handleLimit = function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            setLimit(limitonChange);
            dispatch(fetchCardTemplates(e.target.value, currentPage, params.searchText));
        }
    };
    // console.log(templatesList, 'templatesList')
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: 'flex flex-row items-center' }, { children: [_jsx("div", { className: 'w-[12px] h-[20px] bg-[#575ABC] rounded-lg mr-2' }), _jsx("div", __assign({ className: 'text-2xl text-[#27282D] font-bold dark:text-white' }, { children: "Card Templates" }))] })), _jsxs("div", __assign({ className: 'mt-8 bg-white flex flex-row justify-between items-center px-6 py-4 rounded-xl dark:bg-[#27282D]' }, { children: [_jsxs("div", __assign({ className: '' }, { children: [_jsx("label", __assign({ htmlFor: 'search', className: 'text-[#AEB0CF] text-xs font-medium' }, { children: "Search.." })), _jsx(SearchBar, { label: 'Search', handleChange: handleSearch, value: params === null || params === void 0 ? void 0 : params.searchText, name: 'searchText', width: '', bg: 'dark:bg-[#333542]' })] })), _jsxs("div", __assign({ className: 'flex items-center' }, { children: [_jsx("div", { children: _jsx(CustomButton, __assign({ onClick: handleApply, disabled: disableBtn }, { children: _jsx("p", { children: "Apply" }) })) }), resetBtn ?
                                _jsx("div", __assign({ className: 'ml-2 cursor-pointer text-[#27282D] dark:text-white', onClick: handleCancel }, { children: _jsx("p", { children: _jsx("u", { children: "Reset" }) }) })) : ''] }))] })), isLoading ? (_jsx("div", __assign({ className: 'w-full h-96 flex justify-center items-center' }, { children: _jsx("span", __assign({ className: 'text-3xl font-bold text-primary  dark:text-white' }, { children: "Loading..." })) }))) : (templatesList === null || templatesList === void 0 ? void 0 : templatesList.length) > 0 ? (_jsxs("div", { children: [_jsx(BasicTable, { cols: col, data: templatesList }), _jsx(Pagination, { currentPage: currentPage, totalCount: totalTempPages, pageSize: limit > 0 ? limit : 0, onPageChange: function (page) { return setCurrentPage(page); }, onLimitChange: onLimitChange, handleLimit: handleLimit, limit: limitonChange })] })) : (_jsx("div", __assign({ className: 'flex justify-center items-center flex-col mt-6' }, { children: _jsx("p", __assign({ className: 'text-[18px]' }, { children: "No Results found !!" })) })))] }));
};
export default CardTemplates;
