var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../../common/Button';
import Pagination from '../../../common/Pagination/Pagination';
import SearchBar from '../../../common/SearchBar';
import BasicTable from './Table';
import { deleteDepartment, fetchdepartments, resetApiSuccess } from '../../../../features/settings/departmentSlice';
import Settings from '../Settings';
import { validateAplphaNumeric } from '../../../../utils/helpers';
var col = [
    { title: 'S.NO.' },
    { title: 'DEPARTMENTS' },
    { title: 'CARD TEMPLATES' },
    { title: 'ACTION' },
];
var data = [
    {
        id: 1,
        department: 'HFGVDC',
        card: 'Template ID: 2',
    },
    {
        id: 2,
        department: 'HFGVDC',
        card: 'Template ID: 3',
    },
    {
        id: 3,
        department: 'HFGVDC',
        card: 'Template ID: 4',
    },
    {
        id: 4,
        department: 'HFGVDC',
        card: 'Template ID: 5',
    }
];
var initialVal = {
    searchText: ''
};
var initialValues = {
    department_name: '',
    template_id: null,
    department_logo_file: ''
};
var Departments = function () {
    var dispatch = useDispatch();
    var _a = useSelector(function (state) { return state.departments; }), departmentList = _a.departmentList, isLoading = _a.isLoading, metadata = _a.metadata, isCreateSuccess = _a.isCreateSuccess;
    var totalDepartments = metadata.totalDepartments, totalPages = metadata.totalPages;
    var _b = useState(1), currentPage = _b[0], setCurrentPage = _b[1];
    var _c = useState(initialVal), para = _c[0], setPara = _c[1];
    var _d = useState(10), limit = _d[0], setLimit = _d[1];
    var _e = useState(10), limitonChange = _e[0], setLimitonChange = _e[1];
    var _f = useState(false), resetBtn = _f[0], setResetBtn = _f[1];
    var _g = useState(true), disableBtn = _g[0], setDisableBtn = _g[1];
    var handleSearch = function (event) {
        var _a;
        var _b = event.target, name = _b.name, value = _b.value;
        if (value.length === 1) {
            var isValid = validateAplphaNumeric(value);
            if (!isValid) {
                return;
            }
        }
        setDisableBtn(false);
        setPara(__assign(__assign({}, para), (_a = {}, _a[name] = value, _a)));
    };
    var handleApply = function () {
        setResetBtn(true);
        setCurrentPage(1);
        dispatch(fetchdepartments(limit, 1, para.searchText));
    };
    var handleCancel = function () {
        setDisableBtn(true);
        setResetBtn(false);
        setPara(__assign(__assign({}, para), { searchText: '' }));
        setCurrentPage(1);
        dispatch(fetchdepartments(limit, 1, ''));
    };
    useEffect(function () {
        dispatch(fetchdepartments(limit, currentPage, para.searchText));
    }, [currentPage]);
    var deleteDept = function (id) {
        dispatch(deleteDepartment(id));
    };
    var onLimitChange = function (event) {
        var value = event.target.value;
        if (value && Number(value) === 0)
            return;
        var re = /^[0-9\b]+$/;
        if ((value && !re.test(value)) || value > totalDepartments) {
            return;
        }
        setLimitonChange(event.target.value);
    };
    var handleLimit = function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            if (limitonChange > 0) {
                setLimit(limitonChange);
                dispatch(fetchdepartments(e.target.value, currentPage, para.searchText));
            }
        }
    };
    useEffect(function () {
        if (isCreateSuccess == true) {
            dispatch(resetApiSuccess());
            dispatch(fetchdepartments(10, 1, ''));
        }
    }, [isCreateSuccess]);
    // console.log(departmentList)
    return (_jsxs(_Fragment, { children: [_jsx(Settings, { fetchdepartments: fetchdepartments }), _jsxs("div", __assign({ className: 'mt-5 bg-white flex flex-row justify-between items-center px-6 py-4 rounded-xl dark:bg-[#27282D]' }, { children: [_jsxs("div", __assign({ className: '' }, { children: [_jsx("label", __assign({ htmlFor: 'search', className: 'text-[#AEB0CF] text-xs font-medium' }, { children: "Search.." })), _jsx(SearchBar, { label: 'Search', handleChange: handleSearch, value: para.searchText, name: 'searchText', width: '', bg: 'dark:bg-[#333542]' })] })), _jsxs("div", __assign({ className: 'flex items-center' }, { children: [_jsx("div", { children: _jsx(CustomButton, __assign({ onClick: handleApply, disabled: disableBtn }, { children: _jsx("p", { children: "Apply" }) })) }), resetBtn ?
                                _jsx("div", __assign({ className: 'ml-2 cursor-pointer text-[#27282D] dark:text-white', onClick: handleCancel }, { children: _jsx("p", { children: _jsx("u", { children: "Reset" }) }) })) : ''] }))] })), isLoading ? (_jsx("div", __assign({ className: 'w-full h-96 flex justify-center items-center' }, { children: _jsx("span", __assign({ className: 'text-3xl font-bold text-primary dark:text-white' }, { children: "Loading..." })) }))) : (departmentList === null || departmentList === void 0 ? void 0 : departmentList.length) > 0 ? (_jsxs("div", { children: [_jsx(BasicTable, { cols: col, data: departmentList, deleteDept: deleteDept, fetchdepartments: fetchdepartments, currentPage: currentPage, limit: limit }), _jsx(Pagination, { currentPage: currentPage, totalCount: totalDepartments, pageSize: limit > 0 ? limit : 0, onPageChange: function (page) { return setCurrentPage(page); }, onLimitChange: onLimitChange, handleLimit: handleLimit, limit: limitonChange })] })) : (_jsx("div", __assign({ className: 'flex justify-center items-center flex-col mt-6' }, { children: _jsx("p", __assign({ className: 'text-[18px] dark:text-white' }, { children: "No Results found !!" })) })))] }));
};
export default Departments;
