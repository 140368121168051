var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useRef, useState } from 'react';
import { uuid } from '../../utils/helpers';
import ImagePlaceholder from '../../assets/icons/ImagePlaceholder.svg';
import Options from '../../assets/icons/Options.svg';
import { CustomCheckbox } from '../common/Checkbox';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axios';
import Status from './Status/Status';
import useOutsideClick from './ClickOutsideHook';
var initialValues = {
    rank_id: '',
    department_id: '',
    designation_id: '',
    search_key: '',
};
var WorkTable = function (_a) {
    var data = _a.data, cols = _a.cols, handlechange = _a.handlechange, handleCheck = _a.handleCheck, check = _a.check, requestList = _a.requestList, employeesListing = _a.employeesListing;
    var navigate = useNavigate();
    var _b = useState(false), actionDropdown = _b[0], setActionDropdown = _b[1];
    var _c = useState(null), actionId = _c[0], setActionId = _c[1];
    var _d = useState(false), modalIsOpen = _d[0], setIsOpen = _d[1];
    var handleAction = function (e, id) {
        if (e.target) {
            setActionDropdown(function (prev) { return !prev; });
            setActionId(id);
        }
        else {
            setActionDropdown(false);
        }
    };
    var handleViewRoute = function (id) {
        navigate("/employees/view/".concat(id));
    };
    var cardRequest = function (data) {
        axiosInstance
            .post('/admin/employee_cards', { cardList: data })
            .then(function (res) {
            setIsOpen(true);
            closeModal();
        })
            .catch(function (error) { });
    };
    var requestID = function (id) {
        cardRequest([id]);
        employeesListing('', initialValues, 10, 1);
    };
    function openModal() {
        setIsOpen(true);
        var cardRequest = requestList.map(function (item) { return item.id; });
        cardRequest(cardRequest);
    }
    function closeModal() {
        setIsOpen(false);
    }
    var impactRef = useRef();
    useOutsideClick(impactRef, function () { return setActionDropdown(false); });
    return (_jsx("div", { children: _jsx("div", __assign({ className: 'flex flex-col' }, { children: _jsx("div", __assign({ className: 'sm:-mx-6 lg:-mx-8' }, { children: _jsx("div", __assign({ className: 'inline-block min-w-full sm:px-6 lg:px-8' }, { children: _jsx("div", __assign({ className: 'overflow-x-auto' }, { children: _jsxs("table", __assign({ className: 'min-w-full border-separate border-spacing-y-2' }, { children: [_jsx("thead", __assign({ className: 'bg-white dark:bg-[#27282D]' }, { children: _jsxs("tr", __assign({ className: '' }, { children: [_jsx("th", __assign({ scope: 'col', className: 'first:rounded-l-xl px-4 py-4 text-left' }, { children: _jsxs("div", __assign({ className: 'inline-flex items-center' }, { children: [_jsx(CustomCheckbox, { handleCheck: function (event) { return handleCheck(event); }, 
                                                            // name={''}
                                                            ischecked: check }), _jsx("label", __assign({ className: 'text-sm font-bold text-[#AEB0CF] dark:text-[#6B6C7E]' }, { children: "All" }))] })) })), cols.map(function (element) { return (_jsx(_Fragment, { children: _jsx("th", __assign({ scope: 'col', className: 'first:rounded-l-xl last:rounded-r-xl text-xs font-bold text-[#AEB0CF] px-4 py-4 text-left dark:text-[#6B6C7E]' }, { children: element.title }), uuid()) })); })] })) })), _jsx("tbody", __assign({ className: 'bg-white dark:bg-[#27282D]' }, { children: data.map(function (item) {
                                        var _a;
                                        return (_jsxs("tr", __assign({ className: "".concat((item === null || item === void 0 ? void 0 : item.check) ? 'bg-[#E4E9F8] dark:bg-[#43454A]' : '') }, { children: [_jsx("td", __assign({ className: 'first:rounded-l-xl px-4 py-4 whitespace-nowrap text-sm text-[#27282D]  dark:text-white' }, { children: _jsx(CustomCheckbox, { handleCheck: function (e) { return handlechange(e, item); }, 
                                                        // name={''}
                                                        ischecked: item.check, disabled: item.is_card_requested == 1 }) })), _jsx("td", __assign({ className: 'first:rounded-l-xl px-4 py-4 whitespace-nowrap text-sm text-[#27282D]  dark:text-white' }, { children: (item === null || item === void 0 ? void 0 : item.id) == 'undefined' ? 'N/A' : item === null || item === void 0 ? void 0 : item.id })), _jsx("td", __assign({ className: 'first:rounded-l-xl px-4 py-4 whitespace-nowrap text-sm text-[#27282D]  dark:text-white' }, { children: (item === null || item === void 0 ? void 0 : item.unique_id) == null ? 'N/A' : item === null || item === void 0 ? void 0 : item.unique_id })), _jsx("td", __assign({ className: ' px-4 py-4 whitespace-nowrap text-sm text-[#27282D]  dark:text-white flex-wrap' }, { children: _jsxs("div", __assign({ className: 'flex flex-row items-center ' }, { children: [_jsx("img", { className: 'w-[50px] h-[50px] rounded-full', src: (item === null || item === void 0 ? void 0 : item.image) ? item === null || item === void 0 ? void 0 : item.image : ImagePlaceholder, alt: '' }), _jsxs("div", { children: [_jsx("p", __assign({ className: 'ml-1' }, { children: "".concat(item.first_name, " ").concat(item.middle_name != null ? item.middle_name : '', " ").concat(item.last_name) })), item.phone ? (_jsx("p", __assign({ className: ' text-xs text-[#27282D] text-center dark:text-[#8D8D90]' }, { children: item.phone }))) : ('')] })] })) })), _jsx("td", __assign({ className: ' px-4 py-4 whitespace-nowrap text-sm text-[#27282D]  dark:text-white' }, { children: item.employeeRole.map(function (i) {
                                                        return _jsx("div", { children: i.department.department_name });
                                                    }) })), _jsx("td", __assign({ className: ' px-4 py-4 whitespace-nowrap text-sm text-[#27282D]  dark:text-white' }, { children: item.employeeRole.map(function (i) {
                                                        return _jsx("div", { children: i.designation.designation });
                                                    }) })), _jsx("td", __assign({ className: ' px-4 py-4 whitespace-nowrap text-sm text-[#27282D]  dark:text-white' }, { children: _jsx(Status, { children: (item === null || item === void 0 ? void 0 : item.employeecard) ? item === null || item === void 0 ? void 0 : item.employeecard.card_status : 'NOT REQUESTED' }) })), _jsxs("td", __assign({ className: 'relative last:rounded-r-xl px-4 py-4 whitespace-nowrap text-sm text-[#27282D]  dark:text-white' }, { children: [_jsx("img", { onClick: function (e) { return handleAction(e, item.id); }, className: 'cursor-pointer', src: Options, alt: '' }), actionDropdown && actionId === item.id ? (_jsxs("ul", __assign({ ref: impactRef, className: ' bg-white shadow-xl border-2 border-[#E1E7F8] rounded-xl absolute top-[58px] left-[-100px] z-10 dark:bg-[#333542] dark:border-[#6B6C7E]' }, { children: [_jsx("li", __assign({ className: 'px-4 border-b-2 border-b-[#E1E7F8] py-3 hover:text-primary cursor-pointer dark:text-white dark:border-b-[#6B6C7E] dark:hover:text-[#AEB0CF]' }, { children: ((_a = item === null || item === void 0 ? void 0 : item.employeecard) === null || _a === void 0 ? void 0 : _a.card_status) === 'REQUESTED' ? (_jsx("span", __assign({ className: 'text-[#AEB0CF]' }, { children: "Request for Card" }))) : (_jsx("span", __assign({ onClick: function () { return requestID(item.id); } }, { children: "Request for Card" }))) })), _jsx("li", __assign({ className: 'px-4 border-b-2 border-b-[#E1E7F8] py-3 hover:text-primary cursor-pointer dark:text-white dark:border-b-[#6B6C7E] dark:hover:text-[#AEB0CF]' }, { children: _jsx("span", __assign({ onClick: function () { return handleViewRoute(item.id); } }, { children: "View Details " })) }))] }))) : ('')] }))] }), uuid()));
                                    }) }))] })) })) })) })) })) }));
};
export default WorkTable;
