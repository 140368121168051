var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
export var CustomCheckbox = function (_a) {
    var defaultChecked = _a.defaultChecked, handleCheck = _a.handleCheck, ischecked = _a.ischecked, label = _a.label, name = _a.name, value = _a.value, 
    // color,
    disabled = _a.disabled, error = _a.error;
    return (_jsxs("div", __assign({ className: 'inline-flex items-center' }, { children: [_jsx("div", __assign({ className: 'cursor-pointer w-[40px] h-[40px] flex justify-center items-center rounded-full hover:bg-[#575abc1A]' }, { children: _jsx("input", { className: "cursor-pointer w-[16px] h-[16px] rounded border-[1px] border-[#AEB0CF]\n            ".concat(ischecked ? 'bg-[#575ABC]' : 'bg-[#ffffff]', " hover:bg-[red]"), type: 'checkbox', name: name, id: label, checked: ischecked, defaultChecked: defaultChecked, onChange: handleCheck, disabled: disabled }) })), _jsx("label", __assign({ className: 'dark:text-white' }, { children: label }))] })));
};
