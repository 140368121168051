var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Calendar from '../../../assets/icons/Calendar.svg';
export var DateRangePicker = function (_a) {
    var startDate = _a.startDate, onChange = _a.onChange, endDate = _a.endDate;
    return (_jsxs("div", __assign({ className: 'date-range-picker relative' }, { children: [_jsxs("div", { children: [_jsx("label", __assign({ htmlFor: 'datepicker', className: "absolute top-3 left-8 z-10 text-[#AEB0CF] text-sm ".concat(startDate ? 'hidden' : '') }, { children: "DD-MM-YYYY" })), _jsx(DatePicker, { className: "h-[43px] w-[240px] cursor-pointer bg-[#F5F8FF] py-4 w-full rounded-lg border border-[#AEB0CF]\n        dark:border-transparent\n        focus:bg-white outline-none dark:bg-[#27282D] text-[#27282D] dark:text-white ".concat(startDate ? 'px-4' : 'px-8'), id: 'datepicker', selected: startDate, onChange: onChange, startDate: startDate, endDate: endDate, selectsRange: true })] }), _jsx("label", __assign({ htmlFor: 'datepicker' }, { children: _jsx("img", { className: "cursor-pointer absolute top-3 left-2 ".concat(startDate ? 'hidden' : ''), src: Calendar, alt: '' }) }))] })));
};
