var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import * as user from '../../utils/auth';
import { toast } from 'react-toastify';
var initialState = {
    user: {},
    isLoading: false,
    apiSuccess: false,
    access_token: '',
    loginSuccess: false,
    errors: {
        login: {},
        forgetPassword: {},
        resetPassword: {},
        updatePassword: '',
    },
};
var showToastMessage = function (message, type) {
    if (type === 'error') {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    else {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
};
export var loginSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        formReset: function (state) {
            state.errors.login = {};
            state.isLoading = true;
        },
        resetLoading: function (state) {
            state.isLoading = true;
        },
        resetapiSuccess: function (state) {
            state.apiSuccess = false;
        },
        loginSuccessful: function (state, action) {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            state.isLoading = false;
            if (!((_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.new_user)) {
                state.user = action === null || action === void 0 ? void 0 : action.payload;
                localStorage.setItem('auth_user', JSON.stringify(state.user));
                user.login((_e = (_d = (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.token) === null || _e === void 0 ? void 0 : _e.token);
            }
            else {
                state.access_token = (_h = (_g = (_f = action === null || action === void 0 ? void 0 : action.payload) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.token) === null || _h === void 0 ? void 0 : _h.token;
            }
        },
        authenticationSuccessful: function (state, action) {
            if (action.payload.email) {
                state.user = {
                    email: action.payload.email,
                };
            }
        },
        forgotPasswordSuccessful: function (state, action) {
            if (action.payload.status) {
                state.isLoading = false;
                state.apiSuccess = true;
                showToastMessage(action.payload.data.message, 'success');
            }
        },
        forgotPasswordFailure: function (state, action) {
            state.errors.forgetPassword = action.payload;
            state.isLoading = false;
            showToastMessage(action.payload, 'error');
        },
        loginFailure: function (state, action) {
            state.errors.login = action.payload;
            state.isLoading = false;
            showToastMessage(action.payload, 'error');
        },
        updatedPasswordSuccessful: function (state, action) {
            if (action.payload.status) {
                state.user = action.payload.data;
                state.isLoading = false;
                localStorage.setItem('auth_user', JSON.stringify(state.user));
                user.login(action.payload.data.token.token);
                showToastMessage('Password Updated Successfully', 'success');
            }
        },
        updatePasswordFailure: function (state, action) {
            state.errors.updatePassword = action.payload;
            state.isLoading = false;
            showToastMessage(action.payload.message, 'error');
        },
        resetPasswordSuccessful: function (state, action) {
            if (action.payload.status) {
                state.isLoading = false;
                state.apiSuccess = true;
                showToastMessage(action.payload.message, 'success');
            }
        },
        resetPasswordFailure: function (state, action) {
            state.errors.forgetPassword = action.payload;
            state.isLoading = false;
            showToastMessage(action.payload, 'error');
        },
        loggedOut: function (state) {
            state.user = {};
            // state.access_token = ''
            user.logout();
        },
        clearAccessToken: function (state) {
            state.access_token = '';
        },
    },
});
export var resetLoading = (_a = loginSlice.actions, _a.resetLoading), loginSuccessful = _a.loginSuccessful, loginFailure = _a.loginFailure, authenticationSuccessful = _a.authenticationSuccessful, loggedOut = _a.loggedOut, formReset = _a.formReset, resetapiSuccess = _a.resetapiSuccess, forgotPasswordSuccessful = _a.forgotPasswordSuccessful, forgotPasswordFailure = _a.forgotPasswordFailure, updatedPasswordSuccessful = _a.updatedPasswordSuccessful, updatePasswordFailure = _a.updatePasswordFailure, clearAccessToken = _a.clearAccessToken, resetPasswordSuccessful = _a.resetPasswordSuccessful, resetPasswordFailure = _a.resetPasswordFailure;
export default loginSlice.reducer;
export var login = function (data) {
    return apiCallBegan({
        url: '/login',
        method: 'POST',
        data: data,
        onStart: formReset.type,
        onSuccess: loginSuccessful.type,
        onError: loginFailure.type,
    });
};
export var authenticate = function () {
    return apiCallBegan({
        url: '/authenticate',
        method: 'GET',
        onSuccess: authenticationSuccessful.type,
        onError: loggedOut.type,
    });
};
export var logout = function () {
    return apiCallBegan({
        url: '/logout',
        method: 'POST',
        onSuccess: loggedOut.type,
    });
};
export var forgetPassword = function (data) {
    return apiCallBegan({
        url: '/forgotPassword',
        method: 'POST',
        data: data,
        onStart: resetLoading.type,
        onSuccess: forgotPasswordSuccessful.type,
        onError: forgotPasswordFailure.type,
    });
};
export var updatePassword = function (data) {
    return apiCallBegan({
        url: '/updatePassword',
        method: 'POST',
        data: data,
        onStart: resetLoading.type,
        onSuccess: updatedPasswordSuccessful.type,
        onError: updatePasswordFailure.type,
    });
};
export var resetPassword = function (data) {
    return apiCallBegan({
        url: '/resetPassword',
        method: 'POST',
        data: data,
        onStart: resetLoading.type,
        onSuccess: resetPasswordSuccessful.type,
        onError: resetPasswordFailure.type,
    });
};
