import { useEffect } from 'react';
var useOutsideClick = function (ref, callback) {
    useEffect(function () {
        var handleClickOutside = function (evt) {
            if (ref.current && !ref.current.contains(evt.target)) {
                callback();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return function () {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });
};
export default useOutsideClick;
