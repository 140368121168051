var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Calendar from '../../assets/icons/Calendar.svg';
export var Datepicker = function (_a) {
    var startDate = _a.startDate, onChange = _a.onChange, placeholderText = _a.placeholderText, value = _a.value, name = _a.name, showYearDropdown = _a.showYearDropdown, maxDate = _a.maxDate, autoComplete = _a.autoComplete, selected = _a.selected;
    return (_jsx("div", __assign({ className: '' }, { children: _jsxs("div", __assign({ className: 'relative' }, { children: [_jsx("label", __assign({ htmlFor: 'datepicker' }, { children: _jsx("img", { className: 'absolute top-3 left-4 z-10 mt-1.5', src: Calendar, alt: '' }) })), _jsx(DatePicker, { className: 'cursor-pointer bg-[#F5F8FF] px-10 py-4 w-full rounded-lg border border-transparent \n        hover:border-[#AEB0CF] focus:border-[#AEB0CF] focus:bg-white outline-none dark:bg-[#333542]', id: 'datepicker', placeholderText: placeholderText, selected: selected, onChange: onChange, value: value, name: name, showYearDropdown: showYearDropdown, maxDate: maxDate, autoComplete: autoComplete })] })) })));
};
