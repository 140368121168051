var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector, useDispatch } from 'react-redux';
import { employeeStatisticDetails } from '../../../features/employees/employeesslice';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);
function Chart() {
    var _a;
    var employeeStatistics = useSelector(function (state) { return state.employees; }).employeeStatistics;
    var dispatch = useDispatch();
    useEffect(function () {
        dispatch(employeeStatisticDetails());
    }, []);
    var options = {
        responsive: true,
        barThickness: 50,
        barPercentage: 0.5,
        maxBarThickness: 50,
        chartArea: {
            backgroundColor: 'rgba(255,255,255)',
        },
        scales: {
            x: {
                border: {
                    display: true,
                },
                grid: {
                    display: false,
                },
                min: 0,
                max: 100,
                ticks: {
                    autoSkip: false,
                    padding: 2,
                    font: {
                        size: 10
                    }
                },
            },
            y: {
                border: {
                    display: false,
                },
                grid: {
                    borderDash: [5, 5],
                    borderDashOffset: 2,
                },
                min: 0,
                max: 100,
                ticks: {
                    stepSize: 20
                }
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                anchor: 'end',
                align: '-90',
                color: ['#FFD981', '#575ABC', '#FFD981', '#575ABC'],
                font: {
                    weight: 'bold',
                    size: 14
                },
            }
        },
    };
    var lables = [];
    var dataa = [];
    (_a = employeeStatistics === null || employeeStatistics === void 0 ? void 0 : employeeStatistics.employees_deptwise) === null || _a === void 0 ? void 0 : _a.map(function (item) {
        var _a;
        var x = (_a = item === null || item === void 0 ? void 0 : item.department_name) === null || _a === void 0 ? void 0 : _a.split(' ');
        return lables.push(x) && dataa.push(item.total);
    });
    var data = {
        labels: lables,
        borderColor: '#fffff',
        datasets: [
            {
                data: dataa,
                backgroundColor: ['#FFD981', '#575ABC', '#FFD981', '#575ABC'],
                borderRadius: 8,
            },
        ],
    };
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: '' }, { children: _jsx(Bar, { options: options, data: data }) })) }));
}
export default Chart;
