var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
export var RadioButton = function (_a) {
    var items = _a.items, name = _a.name, onChange = _a.onChange, checked = _a.checked, defaultValue = _a.defaultValue, row = _a.row, label = _a.label, disabled = _a.disabled, value = _a.value;
    return (_jsxs("div", __assign({ className: 'w-[40px] h-[40px] flex justify-center items-center rounded-full hover:bg-[#575abc1A]' }, { children: [_jsx("input", { className: 'w-[16px] h-[16px]', type: "radio", name: name, checked: checked, onChange: onChange, defaultValue: defaultValue, value: value }), _jsx("label", __assign({ className: "form-check-label inline-block text-gray-800", htmlFor: "inlineRadio10" }, { children: label }))] })));
};
