import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import cardsReducer from './cards/cardsSlice';
import rankReducer from './settings/rankSlice';
import departmentsReducer from './settings/departmentSlice';
import designationReducer from './settings/designationSlice';
import userRolesReducer from './settings/userRoleSlice';
import usersReducer from './user/userSlice';
import employeesReducer from './employees/employeesslice';
import dropdownReducer from './dropdown/dropdown';
import dashboardReducer from './dashboard/dashboard';
export default combineReducers({
    auth: authReducer,
    cards: cardsReducer,
    rank: rankReducer,
    employees: employeesReducer,
    departments: departmentsReducer,
    designations: designationReducer,
    userRoles: userRolesReducer,
    users: usersReducer,
    dropdown: dropdownReducer,
    dashboard: dashboardReducer,
});
