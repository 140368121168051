var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var statusList = [
    {
        list: ['REQUESTED'],
        class: 'bg-[#FF4E4E] ',
    },
    {
        list: ['NOT REQUESTED',],
        class: 'bg-[#00BD08]',
    },
    {
        list: ['IN PROGRESS'],
        class: 'bg-[#FFA65C]',
    },
    {
        list: ['DISTRIBUTED'],
        class: 'bg-[#5383FF]',
    },
    {
        list: ['DELIVERED'],
        class: 'bg-[#FBFF2B]',
    },
    {
        list: ['LOST'],
        class: 'bg-[#FA7910]',
    },
];
var getBgClass = function (status) {
    var className = 'bg-carminePink';
    for (var _i = 0, statusList_1 = statusList; _i < statusList_1.length; _i++) {
        var item = statusList_1[_i];
        if (item === null || item === void 0 ? void 0 : item.list.some(function (x) { return (x === null || x === void 0 ? void 0 : x.toLowerCase()) === (status === null || status === void 0 ? void 0 : status.toLowerCase()); })) {
            className = item === null || item === void 0 ? void 0 : item.class;
            break;
        }
    }
    return className;
};
var Status = function (_a) {
    var children = _a.children;
    return (_jsxs("div", __assign({ className: "bg-[#E4E9F8] dark:bg-[#43454A] w-fit py-1.5 flex space-x-2 items-center pr-4 pl-2 rounded-[44px]" }, { children: [_jsx("div", { className: "mr-1 w-[18px] h-[10px] rounded-lg ".concat(getBgClass(children), "   ") }), _jsx("p", __assign({ className: "text-[#27282D] text-xs dark:text-white" }, { children: children }))] })));
};
export default Status;
