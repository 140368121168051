var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import CustomButton from '../../common/Button';
import Plus from '../../../assets/icons/Plus.svg';
import AddNewUser from './AddNewUser';
import Pagination from '../../common/Pagination/Pagination';
import BasicTable from './Table';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsersList, addNewUser, resetApiSuccess, updateUser, userStatus, deleteUser, } from '../../../features/user/userSlice';
import { fetchRoles, fetchDepartments, fetchDesignation, fetchEmployees, } from '../../../features/dropdown/dropdown';
import Validator from 'validatorjs';
var col = [
    { title: 'S.NO.' },
    { title: 'NAME' },
    { title: 'DEPARTMENT' },
    { title: 'DESIGNATION' },
    { title: 'USER ROLE' },
    { title: 'ACCESS' },
    { title: 'ACTION' },
];
var initialVal = {
    searchText: '',
};
var userValues = {
    employee_id: '',
    designation_id: '',
    role_id: '',
    department_id: '',
};
var Users = function () {
    var dispatch = useDispatch();
    var _a = useSelector(function (state) { return state.users; }), userList = _a.userList, isCreateSuccess = _a.isCreateSuccess, isLoading = _a.isLoading, metadata = _a.metadata, isCreatePending = _a.isCreatePending;
    var totalUsers = metadata.totalUsers, totalPages = metadata.totalPages;
    var _b = useSelector(function (state) { return state.dropdown; }), rolesList = _b.rolesList, departmentList = _b.departmentList, designationList = _b.designationList, employeesList = _b.employeesList;
    var _c = useState(1), currentPage = _c[0], setCurrentPage = _c[1];
    var _d = useState(initialVal), params = _d[0], setParams = _d[1];
    var _e = useState(userValues), userParams = _e[0], setUserParams = _e[1];
    var _f = useState(false), modalIsOpen = _f[0], setIsOpen = _f[1];
    var _g = useState(userValues), formErrors = _g[0], setFormErrors = _g[1];
    var _h = useState(10), limit = _h[0], setLimit = _h[1];
    var _j = useState(10), limitonChange = _j[0], setLimitonChange = _j[1];
    var _k = useState(), userId = _k[0], setUserId = _k[1];
    // console.log(totalUsers, 'totalUsers')
    useEffect(function () {
        dispatch(fetchUsersList(limit, currentPage));
    }, [currentPage]);
    useEffect(function () {
        dispatch(fetchDepartments());
        dispatch(fetchDesignation());
        dispatch(fetchRoles());
        dispatch(fetchEmployees());
    }, []);
    var handleChange = function (e) {
        var _a;
        setFormErrors(userValues);
        if (e.target) {
            var _b = e.target, name = _b.name, value = _b.value;
            setUserParams(__assign(__assign({}, userParams), (_a = {}, _a[name] = Number(value), _a)));
        }
    };
    // auto fill data for edit
    var userData = function (type, data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (type === 'update') {
                setUserId(data === null || data === void 0 ? void 0 : data.id);
                setIsOpen(true);
                setUserParams(data);
            }
            if (type === 'delete') {
                setUserId(data === null || data === void 0 ? void 0 : data.id);
                dispatch(deleteUser(data === null || data === void 0 ? void 0 : data.id));
            }
            return [2 /*return*/];
        });
    }); };
    var handleSubmit = function () {
        var rules = {
            employee_id: 'required',
            designation_id: 'required',
            role_id: 'required',
            department_id: 'required',
        };
        var validation = new Validator(userParams, rules);
        if (validation.fails()) {
            var fieldErrors_1 = {};
            Object.keys(validation.errors.errors).forEach(function (key) {
                fieldErrors_1[key] = validation.errors.errors[key][0];
            });
            var err = Object.keys(fieldErrors_1);
            if (err.length) {
                var input = document.querySelector("input[name=".concat(err[0], "]"));
                if (input) {
                    input.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'start',
                    });
                }
            }
            setFormErrors(fieldErrors_1);
            return false;
        }
        userId ? dispatch(updateUser(userParams, userId)) : dispatch(addNewUser(userParams));
        return true;
    };
    useEffect(function () {
        if (isCreateSuccess == true) {
            closeModal();
            dispatch(resetApiSuccess());
            setUserParams(userValues);
            dispatch(fetchUsersList(10, 1));
        }
    }, [isCreateSuccess]);
    var handleStatus = function (id, status) { return __awaiter(void 0, void 0, void 0, function () {
        var statusNow;
        return __generator(this, function (_a) {
            statusNow = {
                is_active: !status,
            };
            // await axiosInstance
            //     .patch(`/admin/user/update_status/${id}`, statusNow)
            //     .then((response) => {
            //         showToastMessage(response.data.message, 'success')
            //         dispatch(fetchUsersList(10, 1))
            //     })
            //     .catch((error) => {
            //         showToastMessage(error.data.data.message, 'error')
            //     })
            // return true
            dispatch(userStatus(id, statusNow));
            return [2 /*return*/];
        });
    }); };
    function openModal() {
        setIsOpen(true);
        setUserId('');
    }
    function closeModal() {
        setIsOpen(false);
        setUserParams(userValues);
        setFormErrors(userValues);
    }
    var onLimitChange = function (event) {
        var value = event.target.value;
        if (value && Number(value) === 0)
            return;
        var re = /^[0-9\b]+$/;
        if ((value && !re.test(value)) || value > totalUsers) {
            return;
        }
        setLimitonChange(event.target.value);
    };
    var handleLimit = function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            if (limitonChange > 0) {
                dispatch(fetchUsersList(limitonChange, currentPage));
                setLimit(limitonChange);
            }
        }
    };
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: 'flex flex-row items-center' }, { children: [_jsx("div", { className: 'w-[12px] h-[20px] bg-[#575ABC] rounded-lg mr-2' }), _jsx("div", __assign({ className: 'text-2xl text-[#27282D] font-bold dark:text-white' }, { children: "Users" })), _jsxs("div", __assign({ className: 'ml-auto' }, { children: [_jsx(CustomButton, __assign({ icon: Plus, onClick: openModal }, { children: "Add New User" })), modalIsOpen ? (_jsx(AddNewUser, { modalIsOpen: modalIsOpen, openModal: openModal, closeModal: closeModal, departmentList: departmentList, designationList: designationList, rolesList: rolesList, employeesList: employeesList, userParams: userParams, handleChange: handleChange, handleSubmit: handleSubmit, formErrors: formErrors, isCreatePending: isCreatePending, userId: userId })) : ('')] }))] })), _jsx("div", __assign({ className: 'mt-5' }, { children: isLoading ? (_jsx("div", __assign({ className: 'w-full h-96 flex justify-center items-center' }, { children: _jsx("span", __assign({ className: 'text-3xl font-bold text-primary dark:text-white' }, { children: "Loading..." })) }))) : (userList === null || userList === void 0 ? void 0 : userList.length) > 0 ? (_jsxs("div", { children: [_jsx(BasicTable, { cols: col, data: userList, userData: userData, handleStatus: handleStatus, currentPage: currentPage, limit: limit }), _jsx(Pagination, { currentPage: currentPage, totalCount: totalUsers, pageSize: limit > 0 ? limit : 0, onPageChange: function (page) { return setCurrentPage(page); }, onLimitChange: onLimitChange, handleLimit: handleLimit, limit: limitonChange })] })) : (_jsx("div", __assign({ className: 'flex justify-center items-center flex-col mt-6' }, { children: _jsx("p", __assign({ className: 'text-[18px]' }, { children: "No Results found !!" })) }))) }))] }));
};
export default Users;
