var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../../common/Button';
import Pagination from '../../../common/Pagination/Pagination';
import SearchBar from '../../../common/SearchBar';
import Settings from '../Settings';
import BasicTable from './Table';
import { deleteDesignation, fetchDesignations, resetApiSuccess } from '../../../../features/settings/designationSlice';
import { validateAplphaNumeric } from '../../../../utils/helpers';
var col = [
    { title: 'S.NO.' },
    { title: 'DESIGNATION' },
    { title: 'RANK' },
    { title: 'ACTION' },
];
var data = [
    {
        id: 1,
        designation: 'MD',
        rank: 1,
    },
    {
        id: 2,
        designation: 'CEO',
        rank: 2,
    },
    {
        id: 3,
        designation: 'Secretary',
        rank: 3,
    },
    {
        id: 4,
        designation: 'Ministry of Education',
        rank: 1,
    },
];
var initialVal = {
    searchText: ''
};
var Designations = function () {
    var dispatch = useDispatch();
    var _a = useSelector(function (state) { return state.designations; }), designationList = _a.designationList, isLoading = _a.isLoading, metadata = _a.metadata, isCreateSuccess = _a.isCreateSuccess;
    var totalDesignations = metadata.totalDesignations, totalPages = metadata.totalPages;
    var _b = useState(1), currentPage = _b[0], setCurrentPage = _b[1];
    var _c = useState(initialVal), params = _c[0], setParams = _c[1];
    var _d = useState(10), limit = _d[0], setLimit = _d[1];
    var _e = useState(10), limitonChange = _e[0], setLimitonChange = _e[1];
    var _f = useState(false), resetBtn = _f[0], setResetBtn = _f[1];
    var _g = useState(true), disableBtn = _g[0], setDisableBtn = _g[1];
    var handleSearch = function (event) {
        var _a;
        var _b = event.target, name = _b.name, value = _b.value;
        if (value.length === 1) {
            var isValid = validateAplphaNumeric(value);
            if (!isValid) {
                return;
            }
        }
        setDisableBtn(false);
        setParams(__assign(__assign({}, params), (_a = {}, _a[name] = value, _a)));
    };
    var handleSubmit = function () {
        setResetBtn(true);
        setCurrentPage(1);
        dispatch(fetchDesignations(limit, 1, params.searchText));
    };
    var handleCancel = function () {
        setDisableBtn(true);
        setResetBtn(false);
        setParams(__assign(__assign({}, params), { searchText: '' }));
        setCurrentPage(1);
        dispatch(fetchDesignations(limit, 1, ''));
    };
    useEffect(function () {
        dispatch(fetchDesignations(limit, currentPage, params.searchText));
    }, [currentPage]);
    var onLimitChange = function (event) {
        var value = event.target.value;
        if (value && Number(value) === 0)
            return;
        var re = /^[0-9\b]+$/;
        if ((value && !re.test(value)) || value > totalDesignations) {
            return;
        }
        setLimitonChange(event.target.value);
    };
    var handleLimit = function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            if (limitonChange > 0) {
                setLimit(limitonChange);
                dispatch(fetchDesignations(e.target.value, currentPage, params.searchText));
            }
        }
    };
    var deleteDesig = function (id) {
        dispatch(deleteDesignation(id));
    };
    useEffect(function () {
        if (isCreateSuccess == true) {
            dispatch(resetApiSuccess());
            dispatch(fetchDesignations(10, 1, ''));
        }
    }, [isCreateSuccess]);
    // console.log(designationList, 'designationList')
    return (_jsxs("div", { children: [_jsx(Settings, {}), _jsxs("div", __assign({ className: 'mt-5 bg-white flex flex-row justify-between items-center px-6 py-4 rounded-xl dark:bg-[#27282D]' }, { children: [_jsxs("div", __assign({ className: '' }, { children: [_jsx("label", __assign({ htmlFor: 'search', className: 'text-[#AEB0CF] text-xs font-medium' }, { children: "Search.." })), _jsx(SearchBar, { label: 'Search', handleChange: handleSearch, value: params.searchText, name: 'searchText', width: '', bg: 'dark:bg-[#333542]' })] })), _jsxs("div", __assign({ className: 'flex items-center' }, { children: [_jsx("div", { children: _jsx(CustomButton, __assign({ onClick: handleSubmit, disabled: disableBtn }, { children: _jsx("p", { children: "Apply" }) })) }), resetBtn ?
                                _jsx("div", __assign({ className: 'ml-2 cursor-pointer text-[#27282D] dark:text-white', onClick: handleCancel }, { children: _jsx("p", { children: _jsx("u", { children: "Reset" }) }) })) : ''] }))] })), isLoading ? (_jsx("div", __assign({ className: 'w-full h-96 flex justify-center items-center' }, { children: _jsx("span", __assign({ className: 'text-3xl font-bold text-primary dark:text-white' }, { children: "Loading..." })) }))) : (designationList === null || designationList === void 0 ? void 0 : designationList.length) > 0 ? (_jsxs("div", { children: [_jsx(BasicTable, { cols: col, data: designationList, currentPage: currentPage, limit: limit, deleteDesig: deleteDesig }), _jsx(Pagination, { currentPage: currentPage, totalCount: totalDesignations, pageSize: limit > 0 ? limit : 0, onPageChange: function (page) { return setCurrentPage(page); }, onLimitChange: onLimitChange, handleLimit: handleLimit, limit: limitonChange })] })) : (_jsx("div", __assign({ className: 'flex justify-center items-center flex-col mt-6' }, { children: _jsx("p", __assign({ className: 'text-[18px] dark:text-white' }, { children: "No Results found !!" })) })))] }));
};
export default Designations;
