var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import FileUploadIcon from '../../assets/icons/FileUpload.svg';
import PDFPreview from '../../assets/icons/PDFPreview.svg';
import { toast } from 'react-toastify';
var FileUpload = function (_a) {
    var id = _a.id, label = _a.label, _b = _a.acceptMimeTypes, acceptMimeTypes = _b === void 0 ? ['image/png', 'image/jpeg'] : _b, setImage = _a.setImage, _c = _a.title, title = _c === void 0 ? 'Drag and Drop PDF here' : _c, _d = _a.maxSize, maxSize = _d === void 0 ? 5 : _d, // in MB,
    _e = _a.imageUrl, // in MB,
    imageUrl = _e === void 0 ? '' : _e, _f = _a.styleType, styleType = _f === void 0 ? 'lg' : _f, filename = _a.filename, _g = _a.previewData, previewData = _g === void 0 ? '' : _g, setPreviewMeta = _a.setPreviewMeta, removeImage = _a.removeImage, error = _a.error;
    var _h = useState(false), isUploaded = _h[0], setUpload = _h[1];
    var _j = useState(false), active = _j[0], setActive = _j[1];
    var _k = useState({ name: '', url: '', type: '' }), imageMeta = _k[0], setImagePreview = _k[1];
    useEffect(function () {
        // show preview image
        if (imageUrl && imageUrl.startsWith('https')) {
            setUpload(true);
            var name = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
            var lastDot = name.lastIndexOf('.');
            var ext = name.substring(lastDot + 1);
            setImagePreview({
                name: name,
                url: imageUrl,
                type: ext === 'pdf' ? 'application/pdf' : 'image/png',
            });
        }
        if (previewData) {
            setImagePreview(previewData);
            setUpload(true);
        }
    }, [previewData, imageUrl]);
    var validation = function (file) {
        var maxSizeInBytes = Number(maxSize) * Math.pow(1024, 2);
        if (file.size > maxSizeInBytes) {
            // show toast message;
            // console.log(`File Size Exceeds the ${maxSize}`);
            toast.error("File Size Exceeds the ".concat(maxSize), {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (!acceptMimeTypes.includes(file.type)) {
            // console.log('Unsupported file selected!');
            toast.error('Unsupported file selected!', {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        return true;
    };
    var convertBase64 = function (file) {
        var isValid = validation(file); // validation for file type and size
        if (isValid) {
            var reader_1 = new FileReader();
            reader_1.readAsDataURL(file);
            reader_1.onload = function () {
                var meta = { name: file.name, url: reader_1.result, type: file.type };
                setImagePreview(meta);
                // setPreviewMeta && setPreviewMeta(meta);
                if (setPreviewMeta) {
                    setPreviewMeta(meta);
                }
                setUpload(true);
                setActive(true);
                setImage({
                    url: reader_1.result || '',
                    name: filename,
                    file: file,
                    preview: meta,
                });
            };
        }
    };
    var chooseFile = function (e) {
        e.preventDefault();
        var ele = document.getElementById(id);
        if (ele)
            ele.click();
    };
    var onChange = function (e) {
        e.preventDefault();
        var file = e.target.files[0];
        convertBase64(file);
    };
    var handleCancel = function () {
        setUpload(false);
        setActive(false);
        if (removeImage) {
            removeImage('');
        }
    };
    // drag and drop functionalities
    var onDrag = function (event) {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === 'dragover') {
            setActive(true);
        }
        if (event.type === 'dragleave') {
            setActive(false);
        }
        if (event.type === 'drop') {
            var file = event.dataTransfer.files[0];
            convertBase64(file);
        }
    };
    return (_jsx("div", __assign({ className: "bg-[#F5F8FF] p-6 cursor-pointer rounded-lg dark:bg-[#333542] ".concat(error && 'input-error', " ").concat(active ? 'file-upload-active' : '') }, { children: !isUploaded ? (_jsxs(_Fragment, { children: [_jsx("label", __assign({ htmlFor: id, className: "cursor-pointer ".concat(styleType === 'md' ? 'hidden' : 'block'), onDragOver: function (e) { return onDrag(e); }, onDragLeave: function (e) {
                        onDrag(e);
                    }, onDrop: function (e) {
                        onDrag(e);
                    } }, { children: _jsx("p", __assign({ className: " text-center font-bold text-white text-lg pt-2" }, { children: title })) })), _jsxs("div", __assign({ className: 'flex flex-col justify-center items-center' }, { children: [_jsx("img", { src: FileUploadIcon, alt: "", className: "" }), _jsx("input", { type: "file", id: id, onChange: onChange, accept: acceptMimeTypes.toString(), className: "hidden" }), _jsx("div", __assign({ className: "upload-btn rounded-lg", id: id, role: "presentation", onClick: function (e) { return chooseFile(e); } }, { children: _jsx("p", __assign({ className: 'text-[#575ABC] font-bold text-sm' }, { children: "Click here to Upload" })) })), _jsx("p", __assign({ className: 'text-[#AEB0CF] font-medium text-sm mb-3' }, { children: label }))] }))] })) : (_jsxs("div", __assign({ className: 'flex flex-row justify-around items-center' }, { children: [_jsxs("div", __assign({ className: "flex flex-col justify-center items-center" }, { children: [_jsx("img", { src: FileUploadIcon, alt: "" }), _jsx("p", __assign({ className: "text-[#575ABC] text-center py-2 font-bold" }, { children: "Uploaded!" }))] })), _jsxs("div", __assign({ className: "flex flex-col justify-center items-center" }, { children: [_jsx("img", { src: imageMeta.type === 'application/pdf' ? PDFPreview : imageMeta.url, alt: "", className: "w-[50px] h-[50px]" }), _jsx("p", __assign({ className: "text-[#AEB0CF] text-sm" }, { children: imageMeta.name.length > 10 ? (_jsxs(_Fragment, { children: [imageMeta.name.substring(0, 10), "..."] })) : (_jsx(_Fragment, { children: imageMeta.name })) })), _jsx("button", __assign({ onClick: handleCancel, type: "button", className: "text-[#575ABC] font-bold text-sm" }, { children: "Click here to Remove" }))] }))] }))) })));
};
export default FileUpload;
