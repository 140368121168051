var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var SearchBar = function (_a) {
    var handleChange = _a.handleChange, value = _a.value, name = _a.name, label = _a.label, width = _a.width, bg = _a.bg;
    return (_jsx("div", { children: _jsx("div", __assign({ className: "flex justify-center" }, { children: _jsx("div", __assign({ className: " xl:w-96" }, { children: _jsxs("div", __assign({ className: "input-group relative flex flex-row items-stretch w-full mb-4" }, { children: [_jsx("svg", __assign({ "aria-hidden": "true", focusable: "false", "data-prefix": "fas", "data-icon": "search", className: "w-4 absolute top-[18px] left-3 z-10 focus:hidden", role: "img", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512" }, { children: _jsx("path", { fill: "#AEB0CF", d: "M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" }) })), _jsx("input", { type: "search", className: "h-[54px] bg-[#F5F8FF] form-control relative flex-auto min-w-0 block w-full px-9 py-1.5\n                    text-base font-normal text-[#27282D] dark:text-white bg-clip-padding ".concat(!bg ? 'bg-[#F5F8FF]' : bg, "\n                    rounded-lg transition ease-in-out m-0 focus:outline-none"), placeholder: "", "aria-label": "Search", "aria-describedby": "button-addon2", name: name, value: value, onChange: handleChange })] })) })) })) }));
};
export default SearchBar;
