var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import LogInfo from './LogInfo';
import OfficialInfo from './OfficialInfo';
import PersoanlInfo from './PersoanlInfo';
var Contents = function (employeeView) {
    var content = [
        {
            id: 0,
            name: 'Personal Information',
        },
        {
            id: 1,
            name: 'Official Information',
        },
        {
            id: 2,
            name: 'Logs info',
        },
    ];
    var pages = [
        _jsx(PersoanlInfo, { params: employeeView }),
        _jsx(OfficialInfo, { params: employeeView }),
        _jsx(LogInfo, { params: employeeView }),
    ];
    var _a = useState(0), Activeindex = _a[0], setActiveIndex = _a[1];
    var handleClick = function (id) {
        setActiveIndex(id);
    };
    return (_jsxs("div", __assign({ className: '' }, { children: [_jsx("div", __assign({ className: 'flex rounded-lg' }, { children: content.map(function (item, index) { return (_jsxs("div", __assign({ className: 'text-[#27282D]' }, { children: [index === Activeindex ? (_jsx("p", __assign({ className: 'm-6 text-[#575ABC] font-semibold text-base cursor-pointer border-b-[3px] border-b-[#575ABC] pb-2', onClick: function () {
                                handleClick(item === null || item === void 0 ? void 0 : item.id);
                            } }, { children: item === null || item === void 0 ? void 0 : item.name }))) : (_jsx("p", __assign({ className: 'm-6 text-[#AEB0CF] font-semibold text-base cursor-pointer hover:text-[#575ABC]\n                                border-b-[3px] border-b-transparent hover:border-b-[#575ABC] pb-2 transition-all dark:text-white', onClick: function () {
                                handleClick(item === null || item === void 0 ? void 0 : item.id);
                            } }, { children: item === null || item === void 0 ? void 0 : item.name }))), _jsx("div", { className: 'w-full  mb-1 font-nunitoRegular font-semibold' })] }))); }) })), pages[Activeindex]] })));
};
export default Contents;
