var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import CustomButton from './common/Button';
import { CustomCheckbox } from './common/Checkbox';
import { Datepicker } from './common/DatePicker';
import { Filter } from './common/Filter';
import { Input } from './common/input/Input';
import Pagination from './common/Pagination/Pagination';
import { RadioButton } from './common/RadioButton';
import SearchBar from './common/SearchBar';
import { SelectInput } from './common/SelectInput';
// import CommonTable from './common/Table'
import Plus from '../assets/icons/Plus.svg';
import CommonTable from './common/Table';
var ComponentToolKit = function () {
    var _a = useState([]), val = _a[0], setVal = _a[1];
    var _b = useState(false), err = _b[0], setErr = _b[1];
    var _c = useState(false), succ = _c[0], setSucc = _c[1];
    var options = [
        { id: 0, value: 'Value1' },
        { id: 1, value: 'Value1' },
        { id: 2, value: 'Value2' },
        { id: 3, value: 'Value3' },
    ];
    var change = function (e) {
        setVal(e.target.value);
    };
    var submit = function () {
        if (val === 'hello') {
            setSucc(true);
            setErr(false);
        }
        else {
            setErr(true);
            setSucc(false);
        }
    };
    var _d = useState(''), selectVal = _d[0], setSelectVal = _d[1];
    var handleChange = function (e) {
        console.log('e:', e.target.value);
        setSelectVal(e.target.value);
    };
    return (_jsxs("div", __assign({ className: 'container mx-auto px-2 bg-[#ccc]' }, { children: [_jsx("h1", __assign({ className: 'text-lg py-2 text-white' }, { children: "Common Components " })), _jsxs("div", __assign({ className: 'grid  gap-4' }, { children: [_jsx(CustomButton, { icon: Plus, disabled: false, onClick: submit, variant: 'primary', size: 'md' }), _jsx(Pagination, {})] })), _jsx("h2", __assign({ className: 'mt-4 underline' }, { children: "Input Compoennts" })), _jsxs("div", __assign({ className: 'grid grid-cols-3 gap-4 pt-5 pb-10' }, { children: [_jsx(Input, { placeholder: 'Text....', name: 'input', value: val, handleChange: change, error: err, success: succ }), _jsx(SelectInput, {}), _jsx(RadioButton, { items: undefined, onChange: undefined }), _jsx(CustomCheckbox, { handleCheck: undefined, name: '' }), _jsx(SearchBar, { label: '', handleChange: undefined, value: undefined, name: undefined, width: undefined, bg: undefined }), _jsx(Filter, { name: 'name', value: selectVal, error: false, helperText: '', handleChange: handleChange, options: options, readonly: false }), _jsx(Datepicker, { onChange: undefined, startDate: undefined })] })), _jsx("h2", __assign({ className: 'mt-4 underline' }, { children: "Table Compoennts" })), _jsx("div", __assign({ className: 'grid  gap-4 pt-5 pb-10' }, { children: _jsx(CommonTable, { cols: [], data: [] }) }))] })));
};
export default ComponentToolKit;
