var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
export var Filter = function (_a) {
    var handleChange = _a.handleChange, value = _a.value, label = _a.label, error = _a.error, helperText = _a.helperText, options = _a.options, width = _a.width, name = _a.name, required = _a.required, readonly = _a.readonly, icon = _a.icon;
    console.log('value:', value);
    return (_jsxs("div", __assign({ className: 'relative' }, { children: [value ? _jsx("p", __assign({ className: ' text-red-400 absolute right-2 -top-4' }, { children: "clear" })) : '', _jsxs("select", __assign({ id: 'countries', className: ' bg-[#F5F8FF] mt-2 h-[54px] border border-transparent hover:border-[1px] hover:border-[#AEB0CF]\n            text-gray-900 text-sm rounded-lg focus:border-[1px] focus:border-[#AEB0CF] text-[#AEB0CF]\n            focus:bg-[white] block w-full p-2.5 ', 
                // required={required}
                value: value, onChange: handleChange, name: name }, { children: [_jsxs("option", __assign({ className: 'bg-[#F5F8FF]', value: "", selected: true }, { children: ["Choose an option", label] })), options.length > 0 ? (options.map(function (item) {
                        return (_jsx("option", __assign({ className: 'bg-[#F5F8FF] text-[#27282D] hover:text-[#575ABC]', value: item.value }, { children: item.value }), item.id));
                    })) : (_jsx("p", __assign({ className: 'text-white p-4 text-xl' }, { children: "Not found !" })))] }))] })));
};
