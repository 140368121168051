var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var styles = {
    borderRadius: '12px', textTransform: 'none', padding: '18px 64px'
};
var CustomButton = function (_a) {
    var disabled = _a.disabled, children = _a.children, onClick = _a.onClick, _b = _a.variant, variant = _b === void 0 ? 'primary' : _b, width = _a.width, size = _a.size, icon = _a.icon, borderRadius = _a.borderRadius, _c = _a.type, type = _c === void 0 ? 'button' : _c;
    return (_jsx("div", __assign({ className: "".concat(width) }, { children: _jsx("button", __assign({ style: styles, className: "h-[46px] bg-[#575ABC] text-[#BCBCBC]  enabled:hover:bg-[#4043AD] ease-linear transition-all duration-150\n        flex items-center justify-center\n        ".concat(disabled ? 'bg-[#EDEDED] text-[#BCBCBC] dark:bg-[#43454A]' : '', "\n        ").concat(size === 'lg' ? 'w-full flex items-center' : 'w-full flex items-center justify-center', "\n        ").concat(variant === 'secondary' ? 'bg-[#E4E9F8] text-[#575ABC] enabled:hover:bg-[#D4DEFB]' : ''), disabled: disabled, onClick: onClick, type: type }, { children: _jsxs("p", __assign({ className: "w-full ".concat(size === 'lg' ? 'justify-center' : 'justify-center', " flex font-bold text-white font-satoshiRegular text-base ").concat(disabled ? 'dark:text-[#27282D]' : '') }, { children: [_jsx("img", { className: 'mr-2', src: icon, alt: '' }), children] })) })) })));
};
CustomButton.defaultProps = {
    disabled: false,
    children: null,
    variant: '',
    width: '',
    size: '',
    icon: '',
    borderRadius: '',
    onClick: function test() { },
};
export default CustomButton;
