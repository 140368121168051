var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useRef } from 'react';
import ArrowDown from '../../../assets/icons/ArrowDown.svg';
var Accordion = function (_a) {
    var title = _a.title, content = _a.content;
    var _b = useState(false), isOpened = _b[0], setOpened = _b[1];
    var _c = useState('0px'), height = _c[0], setHeight = _c[1];
    var contentElement = useRef(null);
    var HandleOpening = function () {
        setOpened(!isOpened);
        setHeight(!isOpened ? "".concat(contentElement.current.scrollHeight, "px") : '0px');
    };
    return (_jsxs("div", __assign({ onClick: HandleOpening, className: 'border-b cursor-pointer bg-white rounded-md dark:bg-[#27282D]' }, { children: [_jsxs("div", __assign({ className: ' p-4 flex justify-between text-white' }, { children: [_jsx("h4", __assign({ className: 'pl-2 text-primary text-base font-semibold' }, { children: title })), isOpened ? (_jsx("img", { src: ArrowDown, className: 'w-4 ' })) : (_jsx("img", { src: ArrowDown, className: 'w-4 rotate-180' }))] })), _jsxs("div", __assign({ ref: contentElement, style: { height: height }, className: 'bg-gray-200 overflow-hidden transition-all duration-200' }, { children: [_jsx("hr", {}), _jsx("p", __assign({ className: 'p-4' }, { children: content }))] }))] })));
};
export default Accordion;
