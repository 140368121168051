var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { showToastMessage } from './../../utils/helpers';
var initialState = {
    departments: {},
    isLoading: false,
    isCreatePending: false,
    isCreateSuccess: false,
    apiSuccess: false,
    userList: [],
    metadata: {
        totalUsers: 0,
        totalPages: 0,
    },
    updateApiSuccess: false,
    isdeleteSuccess: false,
    isdeletePending: false,
    isFailed: false,
};
export var users = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        formReset: function () { },
        resetLoading: function (state) {
            state.isLoading = true;
        },
        CreatePending: function (state) {
            state.isCreatePending = true;
        },
        creationSuccessful: function (state, action) {
            state.isCreatePending = false;
            state.isCreateSuccess = true;
            showToastMessage(action.payload.data.message, 'success');
        },
        resetApiSuccess: function (state) {
            state.isCreateSuccess = false;
        },
        creationFailure: function (state, action) {
            state.isLoading = false;
            state.apiSuccess = false;
            state.isCreatePending = false;
            showToastMessage(action.payload, 'error');
        },
        fetchSuccessful: function (state, action) {
            //   state.cards = action.payload;
        },
        updateSuccessful: function (state) {
            state.isLoading = false;
            state.updateApiSuccess = true;
        },
        updateApi: function (state) {
            state.updateApiSuccess = false;
        },
        SuccessfulList: function (state, action) {
            var _a, _b;
            // console.log(action.payload.data.data.data, 'users...');
            state.userList = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.data.data;
            state.metadata.totalPages = action.payload.data.data.meta.last_page;
            state.metadata.totalUsers = action.payload.data.data.meta.total;
            state.isLoading = false;
            state.apiSuccess = true;
        },
        UserListPending: function (state) {
            state.isLoading = true;
        },
    },
});
export var creationSuccessful = (_a = users.actions, _a.creationSuccessful), creationFailure = _a.creationFailure, formReset = _a.formReset, resetLoading = _a.resetLoading, resetApiSuccess = _a.resetApiSuccess, updateSuccessful = _a.updateSuccessful, updateApi = _a.updateApi, SuccessfulList = _a.SuccessfulList, UserListPending = _a.UserListPending, fetchSuccessful = _a.fetchSuccessful, CreatePending = _a.CreatePending;
export default users.reducer;
export var fetchUsersList = function (limit, page) {
    return apiCallBegan({
        url: "/admin/user?limit=".concat(limit, "&page=").concat(page),
        method: 'GET',
        onStart: UserListPending.type,
        onSuccess: SuccessfulList.type,
        onError: creationFailure.type,
    });
};
export var addNewUser = function (data) {
    return apiCallBegan({
        url: '/admin/user',
        method: 'POST',
        data: data,
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    });
};
export var updateUser = function (data, id) {
    return apiCallBegan({
        url: "/admin/user/".concat(id),
        method: 'PUT',
        data: data,
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    });
};
export var userStatus = function (id, data) {
    return apiCallBegan({
        url: "/admin/user/update_status/".concat(id),
        method: 'PATCH',
        data: data,
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    });
};
export var deleteUser = function (id) {
    return apiCallBegan({
        url: "/admin/user/".concat(id),
        method: 'DELETE',
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    });
};
