var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useState } from 'react';
import { uuid } from '../../../../utils/helpers';
import Options from '../../../../assets/icons/Options.svg';
import UpdateDesignation from './UpdateDesignation';
import { fetchDesignations } from '../../../../features/settings/designationSlice';
import { useDispatch } from 'react-redux';
import useOutsideClick from '../../../common/ClickOutsideHook';
var BasicTable = function (_a) {
    var data = _a.data, cols = _a.cols, currentPage = _a.currentPage, limit = _a.limit, deleteDesig = _a.deleteDesig;
    var dispatch = useDispatch();
    var _b = useState(false), actionDropdown = _b[0], setActionDropdown = _b[1];
    var _c = useState(null), actionId = _c[0], setActionId = _c[1];
    var _d = useState(), desData = _d[0], setDesData = _d[1];
    var _e = useState(false), modalIsOpen = _e[0], setIsOpen = _e[1];
    var impactRef = useRef();
    useOutsideClick(impactRef, function () { return setActionDropdown(false); });
    var handleAction = function (e, id) {
        if (e.target) {
            setActionDropdown(function (prev) { return !prev; });
            setActionId(id);
        }
        else {
            setActionDropdown(false);
        }
        // console.log(e.target, 'target')
    };
    function openModal() {
        setIsOpen(true);
        setActionDropdown(false);
    }
    function closeModal() {
        setIsOpen(false);
    }
    return (_jsx("div", { children: _jsx("div", __assign({ className: "flex flex-col" }, { children: _jsx("div", __assign({ className: "sm:-mx-6 lg:-mx-8" }, { children: _jsx("div", __assign({ className: "inline-block min-w-full sm:px-6 lg:px-8" }, { children: _jsxs("table", __assign({ className: "min-w-full border-separate border-spacing-y-2" }, { children: [_jsx("thead", __assign({ className: "bg-white dark:bg-[#27282D]" }, { children: _jsx("tr", __assign({ className: '' }, { children: cols.map(function (element) { return (_jsx("th", __assign({ scope: "col", className: "first:rounded-l-xl last:rounded-r-xl text-xs font-bold text-[#AEB0CF] px-6 py-4 text-left dark:text-[#6B6C7E]" }, { children: element.title }), uuid())); }) })) })), _jsx("tbody", __assign({ className: 'bg-white dark:bg-[#27282D]' }, { children: data.map(function (item, index) {
                                    var _a, _b;
                                    return (_jsxs("tr", __assign({ className: "" }, { children: [_jsxs("td", __assign({ className: "first:rounded-l-xl px-6 py-4 text-sm text-[#27282D] dark:text-white" }, { children: [(limit * (currentPage - 1)) + (index + 1), "."] })), _jsx("td", __assign({ className: " px-6 py-4 text-sm text-[#27282D] dark:text-white" }, { children: item === null || item === void 0 ? void 0 : item.designation })), _jsx("td", __assign({ className: " px-6 py-4 text-sm text-[#27282D] dark:text-white" }, { children: _jsxs("div", __assign({ className: 'flex flex-row items-center' }, { children: [_jsx("span", { className: "w-[18px] h-[8px] rounded bg-".concat((_a = item === null || item === void 0 ? void 0 : item.rank) === null || _a === void 0 ? void 0 : _a.color_name, " mr-1") }), _jsx("span", { children: (_b = item === null || item === void 0 ? void 0 : item.rank) === null || _b === void 0 ? void 0 : _b.rank_value })] })) })), _jsxs("td", __assign({ className: "relative last:rounded-r-xl px-6 py-4 text-sm text-[#27282D] dark:text-white" }, { children: [_jsx("img", { onClick: function (e) { return handleAction(e, item.id); }, className: 'cursor-pointer', src: Options, alt: '' }), actionDropdown && actionId === item.id ?
                                                        _jsxs("ul", __assign({ ref: impactRef, className: ' bg-white shadow-xl border-2 border-[#E1E7F8] rounded-xl absolute top-[45px] left-[-25px] z-10 dark:bg-[#333542] dark:border-[#6B6C7E]' }, { children: [_jsx("li", __assign({ className: 'px-4 border-b-2 border-b-[#E1E7F8] py-2 hover:text-primary cursor-pointer \n                                dark:text-white dark:border-b-[#6B6C7E] dark:hover:text-[#AEB0CF]', onClick: function () {
                                                                        setDesData(item);
                                                                        openModal();
                                                                    } }, { children: "Edit" })), _jsx("li", __assign({ className: 'px-4 py-2 hover:text-primary cursor-pointer dark:text-white \n                                dark:border-b-[#6B6C7E] dark:hover:text-[#AEB0CF]', onClick: function () { return deleteDesig(item === null || item === void 0 ? void 0 : item.id); } }, { children: "Delete" }))] })) : '', _jsx(UpdateDesignation, { type: 'update', modalIsOpen: modalIsOpen, closeModal: closeModal, desData: desData, fetchDesignations: fetchDesignations })] }))] }), uuid()));
                                }) }))] })) })) })) })) }));
};
export default BasicTable;
function dispatch(arg0) {
    throw new Error('Function not implemented.');
}
