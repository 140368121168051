var _a;
import { showToastMessage } from './../../utils/helpers';
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
var initialState = {
    isLoading: false,
    apiSuccess: false,
    departmentList: [],
    designationList: [],
    rankList: [],
    stateList: [],
    rolesList: [],
    employeesList: [],
    bloodGroupList: [],
};
export var dropdown = createSlice({
    name: 'dropdown',
    initialState: initialState,
    reducers: {
        formReset: function () { },
        DepartmentsListSuccess: function (state, action) {
            state.departmentList = action.payload.data;
            state.isLoading = false;
            state.apiSuccess = true;
        },
        creationFailure: function (state, action) {
            state.isLoading = false;
            state.apiSuccess = false;
            showToastMessage(action.payload.message, 'error');
        },
        ListPending: function (state) {
            state.isLoading = true;
        },
        designationListSuccess: function (state, action) {
            state.designationList = action.payload.data;
            state.isLoading = false;
            state.apiSuccess = true;
        },
        rankListSuccess: function (state, action) {
            state.rankList = action.payload.data;
            state.isLoading = false;
            state.apiSuccess = true;
        },
        stateListSuccess: function (state, action) {
            state.stateList = action.payload.data;
            state.isLoading = false;
            state.apiSuccess = true;
        },
        RolesListSuccess: function (state, action) {
            state.rolesList = action.payload.data;
            state.isLoading = false;
            state.apiSuccess = true;
        },
        EmployeeListSuccess: function (state, action) {
            state.employeesList = action.payload.data;
            state.isLoading = false;
            state.apiSuccess = true;
        },
        bloodGroupListSuccess: function (state, action) {
            state.bloodGroupList = action.payload.data;
            state.isLoading = false;
            state.apiSuccess = true;
        },
    },
});
export var DepartmentsListSuccess = (_a = dropdown.actions, _a.DepartmentsListSuccess), creationFailure = _a.creationFailure, ListPending = _a.ListPending, designationListSuccess = _a.designationListSuccess, rankListSuccess = _a.rankListSuccess, stateListSuccess = _a.stateListSuccess, RolesListSuccess = _a.RolesListSuccess, EmployeeListSuccess = _a.EmployeeListSuccess, bloodGroupListSuccess = _a.bloodGroupListSuccess;
export default dropdown.reducer;
export var fetchDepartments = function () {
    return apiCallBegan({
        url: "/departments/dropdown",
        method: 'GET',
        onStart: ListPending.type,
        onSuccess: DepartmentsListSuccess.type,
        onError: creationFailure.type,
    });
};
export var fetchDesignation = function () {
    return apiCallBegan({
        url: "/designations/dropdown",
        method: 'GET',
        onStart: ListPending.type,
        onSuccess: designationListSuccess.type,
        onError: creationFailure.type,
    });
};
export var fetchRank = function () {
    return apiCallBegan({
        url: "/ranks/dropdown",
        method: 'GET',
        onStart: ListPending.type,
        onSuccess: rankListSuccess.type,
        onError: creationFailure.type,
    });
};
export var fetchStates = function () {
    return apiCallBegan({
        url: "/states/dropdown",
        method: 'GET',
        onStart: ListPending.type,
        onSuccess: stateListSuccess.type,
        onError: creationFailure.type,
    });
};
export var fetchRoles = function () {
    return apiCallBegan({
        url: "/roles/dropdown",
        method: 'GET',
        onStart: ListPending.type,
        onSuccess: RolesListSuccess.type,
        onError: creationFailure.type,
    });
};
export var fetchEmployees = function () {
    return apiCallBegan({
        url: "/employees/dropdown",
        method: 'GET',
        onStart: ListPending.type,
        onSuccess: EmployeeListSuccess.type,
    });
};
export var fetchBloodgroup = function () {
    return apiCallBegan({
        url: "/bloodgroup/dropdown",
        method: 'GET',
        onStart: ListPending.type,
        onSuccess: bloodGroupListSuccess.type,
        onError: creationFailure.type,
    });
};
