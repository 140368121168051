var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loggedOut } from '../features/auth/authSlice';
Modal.setAppElement('#root');
var customStyles = {
    content: {
        top: '40%',
        left: '40%',
        maxWidth: '25%',
        outline: 'none',
    },
    overlay: {
        background: '#00000090',
    },
};
var CardRequestList = function (_a) {
    var modalIsOpen = _a.modalIsOpen, closeModal = _a.closeModal;
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var logout = function () {
        dispatch(loggedOut());
        navigate('/login');
    };
    return (_jsx("div", { children: _jsxs(Modal, __assign({ className: 'relative bg-white dark:bg-[#27282D] p-6 rounded-xl', isOpen: modalIsOpen, onRequestClose: closeModal, style: customStyles, shouldCloseOnOverlayClick: true }, { children: [_jsx("div", __assign({ className: 'mb-2' }, { children: _jsx("p", __assign({ className: 'text-base text-center text-[#27282D] dark:text-[#ffffff]' }, { children: "Are you sure, you want to logout?" })) })), _jsxs("div", __assign({ className: 'flex justify-center' }, { children: [_jsx("button", __assign({ className: 'bg-[#575abc] m-2 text-white px-6 py-2 rounded-lg', onClick: logout }, { children: "Yes" })), _jsx("button", __assign({ className: 'bg-[#575abc] m-2 text-white px-6 py-2 rounded-lg', onClick: closeModal }, { children: "Cancel" }))] }))] })) }));
};
export default CardRequestList;
