var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { showToastMessage } from './../../utils/helpers';
var initialState = {
    departments: {},
    isLoading: false,
    isCreatePending: false,
    isCreateSuccess: false,
    apiSuccess: false,
    departmentList: [],
    metadata: {
        totalDepartments: 0,
        totalPages: 0,
    },
    updateApiSuccess: false,
};
export var departments = createSlice({
    name: 'department',
    initialState: initialState,
    reducers: {
        formReset: function () { },
        resetLoading: function (state) {
            state.isLoading = true;
        },
        CreatePending: function (state) {
            state.isCreatePending = true;
        },
        creationSuccessful: function (state, action) {
            // console.log('action:', action.payload)
            state.isCreatePending = false;
            state.isCreateSuccess = true;
            showToastMessage(action.payload.data.message, 'success');
        },
        resetApiSuccess: function (state) {
            state.isCreateSuccess = false;
            // console.log('dfghjk')
        },
        creationFailure: function (state, action) {
            state.isLoading = false;
            state.apiSuccess = false;
            state.isCreatePending = false;
            showToastMessage(action.payload, 'error');
        },
        fetchSuccessful: function (state, action) {
            //   state.cards = action.payload;
        },
        updateSuccessful: function (state) {
            state.isLoading = false;
            state.updateApiSuccess = true;
        },
        updateApi: function (state) {
            state.updateApiSuccess = false;
        },
        SuccessfulList: function (state, action) {
            // console.log(action.payload, 'departmentSlice');
            state.departmentList = action.payload.data.data.data;
            state.metadata.totalPages = action.payload.data.data.meta.last_page;
            state.metadata.totalDepartments = action.payload.data.data.meta.total;
            state.isLoading = false;
            state.apiSuccess = true;
        },
        DepartmentListPending: function (state) {
            state.isLoading = true;
        },
    },
});
export var creationSuccessful = (_a = departments.actions, _a.creationSuccessful), creationFailure = _a.creationFailure, formReset = _a.formReset, resetLoading = _a.resetLoading, resetApiSuccess = _a.resetApiSuccess, updateSuccessful = _a.updateSuccessful, updateApi = _a.updateApi, SuccessfulList = _a.SuccessfulList, DepartmentListPending = _a.DepartmentListPending, fetchSuccessful = _a.fetchSuccessful, CreatePending = _a.CreatePending;
export default departments.reducer;
export var fetchdepartments = function (limit, page, searchText) {
    return apiCallBegan({
        url: "/admin/department?limit=".concat(limit, "&search_key=").concat(searchText, "&page=").concat(page),
        method: 'GET',
        onStart: DepartmentListPending.type,
        onSuccess: SuccessfulList.type,
        onError: creationFailure.type,
    });
};
export var addDepartment = function (data) {
    return apiCallBegan({
        url: '/admin/department',
        method: 'POST',
        data: data,
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    });
};
export var updateDepartment = function (data, id) {
    return apiCallBegan({
        url: "/admin/department/".concat(id),
        method: 'PUT',
        data: data,
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    });
};
export var deleteDepartment = function (id) {
    return apiCallBegan({
        url: "/admin/department/".concat(id),
        method: 'DELETE',
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    });
};
