import axios from 'axios';
var baseURL = process.env.REACT_APP_BACKEND_URL;
var userToken = localStorage.getItem('user-token') || '';
var axiosInstance = axios.create({
    baseURL: baseURL,
    withCredentials: true,
});
if (userToken.length) {
    /**
     * we'll be setting user token on all request only
     * if we user token on local storage.
     */
    axiosInstance.defaults.headers.common['Authorization'] = "Bearer ".concat(userToken);
}
axiosInstance.defaults.withCredentials = true;
export default axiosInstance;
