import * as CryptoJS from 'crypto-js';
var CLIENT_SECRET = 'HNHGGBHGHBHG7VVGVWH';
export var encryptData = function (data) {
    var encrypt = CryptoJS.AES.encrypt(JSON.stringify(data), CLIENT_SECRET).toString();
    return encrypt.replaceAll('+', '-').replaceAll('/', '_');
};
export var decryptData = function (data) {
    if (data) {
        var decrypt = data.replaceAll('-', '+').replaceAll('_', '/');
        var bytes = CryptoJS.AES.decrypt(decrypt, CLIENT_SECRET);
        if (bytes.toString()) {
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
    }
    return data;
};
