var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var ProgressBar = function (_a) {
    var step = _a.step;
    return (_jsx("div", __assign({ className: 'W-fit h-4 pt-4' }, { children: _jsx("div", { children: _jsx("progress", __assign({ className: 'rounded-full', id: "file", value: step === 1 ? '30' : step === 2 ? '70' : '', max: "100" }, { children: step === 1 ? _jsx("span", { children: "30%" }) : step === 2 ? _jsx("span", { children: "70%" }) : '' })) }) })));
};
export default ProgressBar;
