var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ViewDetails from './ViewDetails';
import { fetchCardDetails } from '../../../../features/cards/cardsSlice';
import { uuid } from '../../../../utils/helpers';
import moment from 'moment';
var LogsInfo = function () {
    var _a;
    var dispatch = useDispatch();
    var id = useParams().id;
    var _b = useSelector(function (state) { return state.cards; }), cards = _b.cards, isLoading = _b.isLoading;
    useMemo(function () {
        dispatch(fetchCardDetails(id));
    }, []);
    // console.log(id, cards);
    return (_jsxs("div", __assign({ className: 'mt-10' }, { children: [_jsx(ViewDetails, {}), isLoading ? (_jsx("div", __assign({ className: 'w-full h-96 flex justify-center items-center' }, { children: _jsx("span", __assign({ className: 'text-3xl font-bold text-primary dark:text-white' }, { children: "Loading..." })) }))) :
                _jsxs(_Fragment, { children: [(_a = cards === null || cards === void 0 ? void 0 : cards.logs) === null || _a === void 0 ? void 0 : _a.map(function (item) { return (_jsxs("div", __assign({ className: 'flex flex-row items-center bg-white rounded-xl mt-5 px-4 dark:bg-[#27282D]' }, { children: [_jsx("p", __assign({ className: 'm-4 text-base text-[#27282D80] dark:text-[#6B6C7E]' }, { children: "".concat((item === null || item === void 0 ? void 0 : item.card_request_at) === '' || (item === null || item === void 0 ? void 0 : item.card_request_at) === null ? '--' : moment((item === null || item === void 0 ? void 0 : item.card_request_at)).format('LT') !== 'Invalid date' && moment((item === null || item === void 0 ? void 0 : item.card_request_at)).format('LT'), " ").concat((item === null || item === void 0 ? void 0 : item.card_request_at) === '' || (item === null || item === void 0 ? void 0 : item.card_request_at) === null ? '--' : moment((item === null || item === void 0 ? void 0 : item.card_request_at)).format('DD/MM/YYYY') !== 'Invalid date' && moment((item === null || item === void 0 ? void 0 : item.card_request_at)).format('DD/MM/YYYY')) })), _jsx("p", __assign({ className: 'm-4 text-base text-[#27282D] dark:text-white' }, { children: item === null || item === void 0 ? void 0 : item.message }))] }), uuid())); }), _jsx("div", __assign({ className: 'mt-5 text-center' }, { children: _jsx("button", __assign({ className: "bg-[#D8D9F17A] text-[#27282D] text-base py-2 px-4 rounded-xl dark:text-white" }, { children: "Show More" })) }))] })] })));
};
export default LogsInfo;
