var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import moment from 'moment';
import React, { useState } from 'react';
import Status from '../../common/Status/Status';
var CardInformation = function (employeeView, Role) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    var _v = useState(true), active = _v[0], setInactive = _v[1];
    var toggle = function () {
        setInactive(!active);
    };
    var cardData = [
        {
            name: 'Card Status:',
            value: (_c = (_b = (_a = employeeView === null || employeeView === void 0 ? void 0 : employeeView.employeeView) === null || _a === void 0 ? void 0 : _a.employeecard) === null || _b === void 0 ? void 0 : _b.card_status) !== null && _c !== void 0 ? _c : 'NOT REQUESTED',
        },
        {
            name: 'Card Requested Date:',
            value: "".concat(((_d = employeeView === null || employeeView === void 0 ? void 0 : employeeView.employeeView) === null || _d === void 0 ? void 0 : _d.employeecard)
                ? ((_f = (_e = employeeView === null || employeeView === void 0 ? void 0 : employeeView.employeeView) === null || _e === void 0 ? void 0 : _e.employeecard) === null || _f === void 0 ? void 0 : _f.card_requested_at) !== null
                    ? moment((_h = (_g = employeeView === null || employeeView === void 0 ? void 0 : employeeView.employeeView) === null || _g === void 0 ? void 0 : _g.employeecard) === null || _h === void 0 ? void 0 : _h.card_requested_at).format('DD-MM-YYYY')
                    : '--'
                : '--'),
        },
        {
            name: 'Card Recived Date',
            value: "".concat(((_j = employeeView === null || employeeView === void 0 ? void 0 : employeeView.employeeView) === null || _j === void 0 ? void 0 : _j.employeecard)
                ? ((_l = (_k = employeeView === null || employeeView === void 0 ? void 0 : employeeView.employeeView) === null || _k === void 0 ? void 0 : _k.employeecard) === null || _l === void 0 ? void 0 : _l.card_received_at) !== null
                    ? moment((_o = (_m = employeeView === null || employeeView === void 0 ? void 0 : employeeView.employeeView) === null || _m === void 0 ? void 0 : _m.employeecard) === null || _o === void 0 ? void 0 : _o.card_received_at).format('DD-MM-YYYY')
                    : '--'
                : '--'),
        },
        {
            name: 'Template Name:',
            value: (_q = (_p = employeeView === null || employeeView === void 0 ? void 0 : employeeView.employeeView) === null || _p === void 0 ? void 0 : _p.employeeRole) === null || _q === void 0 ? void 0 : _q.filter(function (i) {
                if (i.is_primary == 1) {
                    return i;
                }
            }).map(function (item) { var _a; return (_a = item === null || item === void 0 ? void 0 : item.department.template) === null || _a === void 0 ? void 0 : _a.name; }),
        },
    ];
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'container mt-16 bg-[#ffffff] dark:bg-[#27282D] flex justify-center items-center flex-col pb-8 ' }, { children: [_jsx("div", __assign({ className: 'bg-[#F1EFF4] mt-4  w-56 h-40 ' }, { children: active ? (_jsx("img", { className: 'p-2', src: (_s = (_r = employeeView === null || employeeView === void 0 ? void 0 : employeeView.employeeView) === null || _r === void 0 ? void 0 : _r.employeeRole) === null || _s === void 0 ? void 0 : _s.filter(function (i) {
                            if (i.is_primary == 1) {
                                return i;
                            }
                        }).map(function (item) { var _a; return (_a = item === null || item === void 0 ? void 0 : item.department.template) === null || _a === void 0 ? void 0 : _a.image_front; }), alt: 'logo' })) : (_jsx("img", { className: 'p-2', src: (_u = (_t = employeeView === null || employeeView === void 0 ? void 0 : employeeView.employeeView) === null || _t === void 0 ? void 0 : _t.employeeRole) === null || _u === void 0 ? void 0 : _u.filter(function (i) {
                            if (i.is_primary == 1) {
                                return i;
                            }
                        }).map(function (item) { var _a; return (_a = item === null || item === void 0 ? void 0 : item.department.template) === null || _a === void 0 ? void 0 : _a.image_back; }), alt: 'logo' })) })), _jsxs("div", __assign({ className: 'flex items-center mt-12 bg-slate-100 gap-4 p-2  dark:bg-[#7D7A7A]  mb-2 cursor-pointer rounded-lg ' }, { children: [_jsx("button", __assign({ onClick: toggle, className: "".concat(active ? 'bg-white dark:bg-[#27282D] dark:text-white rounded-lg p-2 ' : '') }, { children: "Front" })), _jsx("button", __assign({ onClick: toggle, className: "".concat(active == false ? 'bg-white dark:bg-[#27282D] dark:text-white rounded-lg p-2 ' : '') }, { children: "Back" }))] })), _jsx("div", __assign({ className: 'bg-white rounded-xl ' }, { children: _jsx("div", __assign({ className: ' ' }, { children: cardData.map(function (item, index) { return (_jsxs("div", __assign({ className: 'flex justify-between items-center pt-3 gap-8 border-t border-[#D8D9F1] dark:bg-[#27282D]' }, { children: [_jsx("div", __assign({ className: 'py-4  ' }, { children: _jsx("p", __assign({ className: 'text-[#27282D80] font-semibold text-xs dark:text-[#6B6C7E]' }, { children: item.name })) })), _jsx("div", __assign({ className: 'py-4 col-span-6 ' }, { children: index === 0 ? (_jsx(Status, { children: item.value })) : (_jsx("p", __assign({ className: 'text-[#27282D] font-semibold text-sm dark:text-[#FFFFFF]' }, { children: item.value }))) }))] }))); }) })) }))] })) }));
};
export default CardInformation;
