var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Input } from '../../common/input/Input';
import CustomButton from '../../common/Button';
import { CustomCheckbox } from '../../common/Checkbox';
import EmailIcon from '../../../assets/icons/outline/EmailIcon.svg';
import CallIcon from '../../../assets/icons/outline/CallIcon.svg';
import HomeIcon from '../../../assets/icons/outline/HomeIcon.svg';
import FaxIcon from '../../../assets/icons/outline/FaxIcon.svg';
import WebsiteIcon from '../../../assets/icons/outline/WebsiteIcon.svg';
import DepartmentIcon from '../../../assets/icons/outline/DepartmentIcon.svg';
import CrownIcon from '../../../assets/icons/outline/CrownIcon.svg';
import ArrowDown from '../../../assets/icons/ArrowDown.svg';
import DeleteIcon from '../../../assets/icons/outline/DeleteIcon.svg';
import Plus from '../../../assets/icons/Plus.svg';
import { fetchDepartments, fetchDesignation, fetchStates, } from '../../../features/dropdown/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { SelectInput } from '../../common/SelectInput';
import { Datepicker } from '../../common/DatePicker';
import moment from 'moment';
import { useParams } from 'react-router-dom';
var EmployeeOfficialInfo = function (_a) {
    var params = _a.params, handleChange = _a.handleChange, setParams = _a.setParams, employeeId = _a.employeeId, onStartDate = _a.onStartDate, startDate = _a.startDate, onEndDate = _a.onEndDate, officialFormErrors = _a.officialFormErrors, errors = _a.errors, success = _a.success, handlePrimary = _a.handlePrimary;
    var dispatch = useDispatch();
    var id = useParams().id;
    var _b = useState(true), rotate_1 = _b[0], setRotate_1 = _b[1];
    var _c = useState(true), rotate_2 = _c[0], setRotate_2 = _c[1];
    var _d = useSelector(function (state) { return state.dropdown; }), departmentList = _d.departmentList, designationList = _d.designationList, stateList = _d.stateList;
    var handleClick = function (id) {
        id === 'id_1' ? setRotate_1(!rotate_1) : id === 'id_2' ? setRotate_2(!rotate_2) : '';
    };
    useEffect(function () {
        dispatch(fetchDepartments());
        dispatch(fetchDesignation());
        dispatch(fetchStates());
    }, []);
    var addRole = function () {
        var list = __spreadArray([], params, true);
        list.push({});
        setParams(list);
    };
    var RemoveRole = function (index) {
        var list = __spreadArray([], params, true);
        list === null || list === void 0 ? void 0 : list.splice(index, 1);
        console.log(list);
        setParams(list);
    };
    // const checkDisabled = (params: any) => {
    //   params?.is_primary === 1 || params?.is_primary ? false : true
    // }
    return (_jsx("div", { children: _jsx("div", { children: _jsxs("div", __assign({ className: 'container w-full  mt-2  p-16 bg-white dark:bg-[#27282D]' }, { children: [_jsxs("div", __assign({ className: 'pb-16' }, { children: [_jsx("h1", __assign({ className: 'font-bold text-[#5055BD] text-xl dark:text-white' }, { children: "Official Information" })), _jsxs("p", __assign({ className: 'text-xs text-[#AEB0CF] dark:text-[#6B6C7E]' }, { children: [id ? 'Update' : 'Create', " Employee\u2019s Official Information here."] }))] })), _jsx("div", { className: '  flex-shrink-0 p-4 mt-4 border-t max-h-14 border-[#D8D9F1] dark:border-[#43454A]' }), _jsxs("div", __assign({ className: 'flex justify-between dark:text-white' }, { children: [_jsx("h1", __assign({ className: 'pt-4 font-bold mb-1' }, { children: "Employee Database ID" })), _jsx("div", __assign({ className: 'flex justify-end gap-4' }, { children: _jsxs("div", __assign({ className: 'w-[551px] dark:text-white' }, { children: [_jsx("p", __assign({ className: 'text-[#a1a1aa] text-xs ' }, { children: "Employee DB ID" })), _jsx(Input, { placeholder: 'Enter Employee DB ID', bg: 'bg-[#F5F8FF] dark:bg-transparent dark:bg-[#333542]', value: employeeId, name: 'employee_id' })] })) }))] })), _jsxs("div", { children: [params === null || params === void 0 ? void 0 : params.filter(function (x) { return x; }).map(function (data, index) {
                                return (_jsx(_Fragment, { children: _jsx("div", { children: _jsx("div", __assign({ className: ' text-gray-600 ' }, { children: _jsx("div", __assign({ className: 'container mt-4  ' }, { children: _jsxs("details", __assign({ onClick: function () { return handleClick('id_1'); }, className: ' shadow-rounded group ' }, { children: [_jsxs("summary", __assign({ className: 'list-none flex-wrap items-center cursor-pointer' }, { children: [_jsxs("div", __assign({ className: ' bg-gradient-to-l from-[#8A8DF7] to-[#474AA3] px-4 rounded-lg flex justify-between ' }, { children: [_jsxs("div", __assign({ className: '  flex items-center gap-6' }, { children: [_jsx("p", __assign({ className: ' font-semibold text-white ' }, { children: "Role-".concat(index + 1) })), _jsx("img", { className: "".concat(!rotate_1 ? 'rotate-180' : ''), src: ArrowDown, alt: '' })] })), _jsxs("div", __assign({ className: 'flex items-center gap-4 ' }, { children: [_jsx(CustomCheckbox, { handleCheck: function (event) {
                                                                                        handlePrimary(index);
                                                                                    }, ischecked: data === null || data === void 0 ? void 0 : data.is_primary }), _jsx("p", __assign({ className: 'text-[#FFD981] ' }, { children: "Make this as Primary Role" })), _jsx("div", __assign({ className: 'flex justify-end' }, { children: index === 0 ? '' : _jsx("img", { onClick: function () { return RemoveRole(index); }, src: DeleteIcon, alt: '' }) }))] }))] })), _jsx("p", __assign({ className: 'text-red flex justify-end' }, { children: officialFormErrors["employeeRole.".concat(index, ".is_primary")] }))] })), _jsxs("div", __assign({ className: 'flex justify-between dark:text-white p-6' }, { children: [_jsxs("div", { children: [_jsx("h1", __assign({ className: 'pt-8 font-bold' }, { children: "Department Information" })), _jsx("p", __assign({ className: 'text-[#a1a1aa] text-xs' }, { children: "Select the Department information here." }))] }), _jsx("div", __assign({ className: 'flex justify-end gap-4 mt-8' }, { children: _jsx("div", __assign({ className: 'w-[552px]' }, { children: _jsxs("div", { children: [_jsx(SelectInput, { icon: DepartmentIcon, label: 'Select the Department', bg: 'bg-[#F5F8FF] dark:bg-transparent dark:bg-[#333542]', options: departmentList, handleChange: function (event) {
                                                                                        handleChange(index, event);
                                                                                    }, name: 'department_id', value: data === null || data === void 0 ? void 0 : data.department_id, error: officialFormErrors["employeeRole.".concat(index, ".department_id")], helperText: officialFormErrors["employeeRole.".concat(index, ".department_id")] }), _jsxs("div", __assign({ className: 'flex gap-4 ' }, { children: [_jsx(SelectInput, { icon: CrownIcon, label: 'Select Designation', bg: 'bg-[#F5F8FF] dark:bg-transparent dark:bg-[#333542]', options: designationList, handleChange: function (event) {
                                                                                                handleChange(index, event);
                                                                                            }, name: 'designation_id', value: data === null || data === void 0 ? void 0 : data.designation_id, error: officialFormErrors["employeeRole.".concat(index, ".designation_id")], helperText: officialFormErrors["employeeRole.".concat(index, ".designation_id")] }), _jsx(Input, { placeholder: 'Enter Designation Title', bg: 'bg-[#F5F8FF] dark:bg-transparent dark:bg-[#333542]', handleChange: function (event) {
                                                                                                handleChange(index, event);
                                                                                            }, value: data === null || data === void 0 ? void 0 : data.designation_title, name: 'designation_title', error: officialFormErrors["employeeRole.".concat(index, ".designation_title")], helperText: officialFormErrors["employeeRole.".concat(index, ".designation_title")] })] })), _jsx("div", __assign({ className: 'pt-4 ' }, { children: _jsx(Datepicker, { onChange: function (event) {
                                                                                            onStartDate(event, index);
                                                                                        }, placeholderText: 'Select Start Date(YYYY-MM-DD)', value: (data === null || data === void 0 ? void 0 : data.department_joined_at)
                                                                                            ? moment(data === null || data === void 0 ? void 0 : data.department_joined_at).format('YYYY-MM-DD')
                                                                                            : '', name: 'department_joined_at', showYearDropdown: false, autoComplete: 'off' }) })), _jsx("div", __assign({ className: 'pt-4 pb-2 w-[552px] ' }, { children: _jsx(Datepicker, { onChange: function (event) { return onEndDate(event, index); }, placeholderText: 'Select End Date(YYYY-MM-DD)', value: (data === null || data === void 0 ? void 0 : data.department_out_of_date)
                                                                                            ? moment(data === null || data === void 0 ? void 0 : data.department_out_of_date).format('YYYY-MM-DD')
                                                                                            : '', name: 'department_out_of_date', showYearDropdown: false, autoComplete: 'off' }) }))] }) })) }))] })), _jsx("div", { className: '  flex-shrink-0 p-4 mt-4 border-t max-h-14 border-[#D8D9F1] dark:border-[#43454A]' }), _jsxs("div", __assign({ className: 'flex justify-between dark:text-white ' }, { children: [_jsxs("div", { children: [_jsx("h1", __assign({ className: 'pt-4 pl-4 font-bold' }, { children: "Personal Contact information" })), _jsx("p", __assign({ className: 'text-[#a1a1aa] text-xs pl-4' }, { children: "Enter Employee\u2019s Personal contact information here." }))] }), _jsx("div", __assign({ className: 'flex justify-end gap-4 p-6' }, { children: _jsxs("div", { children: [_jsxs("div", __assign({ className: 'w-[551px] ' }, { children: [_jsx(Input, { icon: CallIcon, placeholder: 'Mobile Number ', type: 'number', bg: 'bg-[#F5F8FF] dark:bg-transparent dark:bg-[#333542]', value: data === null || data === void 0 ? void 0 : data.official_phone, handleChange: function (event) {
                                                                                            handleChange(index, event);
                                                                                        }, name: 'official_phone', error: officialFormErrors["employeeRole.".concat(index, ".official_phone")], helperText: officialFormErrors["employeeRole.".concat(index, ".official_phone")] }), _jsx("br", {}), _jsx(Input, { icon: WebsiteIcon, placeholder: 'Website (Example: www.google.com)', bg: 'bg-[#F5F8FF] dark:bg-transparent dark:bg-[#333542]', value: data.website, handleChange: function (event) {
                                                                                            handleChange(index, event);
                                                                                        }, name: 'website' }), _jsx("br", {}), _jsx(Input, { icon: CallIcon, placeholder: 'Telephone ', bg: 'bg-[#F5F8FF] dark:bg-transparent dark:bg-[#333542]', value: data === null || data === void 0 ? void 0 : data.official_telephone, handleChange: function (event) {
                                                                                            handleChange(index, event);
                                                                                        }, name: 'official_telephone', type: 'number' }), _jsx("br", {}), _jsx(Input, { icon: HomeIcon, placeholder: 'Address Line 1', bg: 'bg-[#F5F8FF] dark:bg-transparent dark:bg-[#333542]', value: data === null || data === void 0 ? void 0 : data.official_address_line_1, handleChange: function (event) {
                                                                                            handleChange(index, event);
                                                                                        }, name: 'official_address_line_1', error: officialFormErrors["employeeRole.".concat(index, ".official_address_line_1")], helperText: officialFormErrors["employeeRole.".concat(index, ".official_address_line_1")] }), _jsx("br", {}), _jsx(Input, { icon: HomeIcon, placeholder: 'Address Line 2', bg: 'bg-[#F5F8FF] dark:bg-transparent dark:bg-[#333542]', value: data === null || data === void 0 ? void 0 : data.official_address_line_2, handleChange: function (event) {
                                                                                            handleChange(index, event);
                                                                                        }, name: 'official_address_line_2' }), _jsx("br", {}), _jsx(Input, { icon: EmailIcon, placeholder: 'Email (Example: ganapathymoorthi@gmail.com)', bg: 'bg-[#F5F8FF] dark:bg-transparent dark:bg-[#333542]', value: data === null || data === void 0 ? void 0 : data.official_email, handleChange: function (event) {
                                                                                            handleChange(index, event);
                                                                                        }, name: 'official_email' }), _jsx("br", {}), _jsx(Input, { icon: FaxIcon, placeholder: 'Fax Number', bg: 'bg-[#F5F8FF] dark:bg-transparent dark:bg-[#333542]', value: data === null || data === void 0 ? void 0 : data.official_fax_no, handleChange: function (event) {
                                                                                            handleChange(index, event);
                                                                                        }, name: 'official_fax_no', type: 'number', error: officialFormErrors["employeeRole.".concat(index, ".official_fax_no")], helperText: officialFormErrors["employeeRole.".concat(index, ".official_fax_no")] }), _jsx("br", {}), _jsx(SelectInput, { label: 'State', bg: 'bg-[#F5F8FF] dark:bg-transparent dark:bg-[#333542]', value: data === null || data === void 0 ? void 0 : data.official_state, handleChange: function (event) {
                                                                                            handleChange(index, event);
                                                                                        }, name: 'official_state', options: stateList }), _jsx("br", {})] })), _jsxs("div", __assign({ className: 'flex gap-2 pt-2' }, { children: [_jsx(Input, { placeholder: 'City', bg: 'bg-[#F5F8FF] dark:bg-transparent dark:bg-[#333542]', value: data === null || data === void 0 ? void 0 : data.official_city, handleChange: function (event) {
                                                                                            handleChange(index, event);
                                                                                        }, name: 'official_city' }), _jsx(Input, { placeholder: 'PinCode', bg: 'bg-[#F5F8FF] dark:bg-transparent dark:bg-[#333542]', value: data === null || data === void 0 ? void 0 : data.official_pincode, handleChange: function (event) {
                                                                                            handleChange(index, event);
                                                                                        }, name: 'official_pincode', error: officialFormErrors["employeeRole.".concat(index, ".official_pincode")], helperText: officialFormErrors["employeeRole.".concat(index, ".official_pincode")], type: 'number' })] }))] }) }))] }))] })) })) })) }) }));
                            }), _jsx("div", __assign({ className: 'mt-8 flex justify-end dark:text-white' }, { children: _jsx(CustomButton, __assign({ icon: Plus, onClick: addRole }, { children: "Add Another Role" })) }))] })] })) }) }));
};
export default EmployeeOfficialInfo;
