var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { uuid } from '../../utils/helpers';
var CommonTable = function (_a) {
    var data = _a.data, cols = _a.cols;
    return (_jsx("div", { children: _jsx("div", __assign({ className: "flex flex-col" }, { children: _jsx("div", __assign({ className: "overflow-x-auto sm:-mx-6 lg:-mx-8" }, { children: _jsx("div", __assign({ className: "py-2 inline-block min-w-full sm:px-6 lg:px-8" }, { children: _jsx("div", __assign({ className: "overflow-x-auto" }, { children: _jsxs("table", __assign({ className: "min-w-full border-separate border-spacing-y-2" }, { children: [_jsx("thead", __assign({ className: "bg-white" }, { children: _jsx("tr", __assign({ className: '' }, { children: cols.map(function (element) { return (_jsx("th", __assign({ scope: "col", className: "last:rounded-r-xl text-sm font-bold text-[#AEB0CF] px-6 py-4 text-left" }, { children: element.header }), uuid())); }) })) })), _jsx("tbody", __assign({ className: 'bg-white' }, { children: data.map(function (item) { return (_jsx("tr", __assign({ className: "" }, { children: cols.map(function (col) { return (_jsx("td", __assign({ className: "last:rounded-r-xl px-6 py-4 whitespace-nowrap text-sm font-medium text-[#27282D]" }, { children: item[col.key] }), uuid())); }) }), uuid())); }) }))] })) })) })) })) })) }));
};
export default CommonTable;
