var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { toast } from 'react-toastify';
var initialState = {
    cards: {},
    allCards: [],
    isLoading: false,
    apiSuccess: false,
    isCreatePending: false,
    isCreateSuccess: false,
    templatesList: [],
    // cardsList: [],
    metadata: {
        totalCardsPages: 0,
        totalCards: 0,
        totalTemplates: 0,
        totalTempPages: 0,
    },
    updateApiSuccess: false,
};
var showToastMessage = function (message, type) {
    if (type === 'error') {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    else {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
};
export var cards = createSlice({
    name: 'card',
    initialState: initialState,
    reducers: {
        formReset: function () { },
        resetLoading: function (state) {
            state.isLoading = true;
        },
        CreatePending: function (state) {
            state.isCreatePending = true;
        },
        creationSuccessful: function (state, action) {
            state.isCreatePending = false;
            state.isCreateSuccess = true;
            showToastMessage(action.payload.data.message, 'success');
        },
        resetApiSuccess: function (state) {
            state.isCreateSuccess = false;
        },
        creationFailure: function (state, action) {
            state.isLoading = false;
            state.apiSuccess = false;
            state.isCreatePending = false;
            showToastMessage(action.payload, 'error');
        },
        setApiSuccess: function (state) {
            state.apiSuccess = false;
        },
        fetchSuccessful: function (state, action) {
            //   state.cards = action.payload;
        },
        updateSuccessful: function (state) {
            state.isLoading = false;
            state.updateApiSuccess = true;
        },
        updateApi: function (state) {
            state.updateApiSuccess = false;
        },
        SuccessfulCardsList: function (state, action) {
            var x = [];
            x = action.payload.data.data.data;
            x.map(function (item) {
                item['check'] = false;
            });
            console.log(x);
            state.allCards = x;
            state.metadata.totalCards = action.payload.data.total;
            state.isLoading = false;
            state.apiSuccess = true;
        },
        SuccessfulList: function (state, action) {
            // console.log(action.payload);
            state.templatesList = action.payload.data.data.data;
            state.metadata.totalTempPages = action.payload.data.data.meta.last_page;
            state.metadata.totalTemplates = action.payload.data.data.meta.total;
            state.isLoading = false;
            state.apiSuccess = true;
        },
        CardDetailsSuccess: function (state, action) {
            // console.log(action.payload);
            state.cards = action.payload.data.data;
            state.isLoading = false;
        },
        CardsListPending: function (state) {
            state.isLoading = true;
        },
    },
});
export var creationSuccessful = (_a = cards.actions, _a.creationSuccessful), creationFailure = _a.creationFailure, formReset = _a.formReset, resetLoading = _a.resetLoading, setApiSuccess = _a.setApiSuccess, updateSuccessful = _a.updateSuccessful, updateApi = _a.updateApi, SuccessfulList = _a.SuccessfulList, CardsListPending = _a.CardsListPending, fetchSuccessful = _a.fetchSuccessful, CardDetailsSuccess = _a.CardDetailsSuccess, CreatePending = _a.CreatePending, resetApiSuccess = _a.resetApiSuccess, SuccessfulCardsList = _a.SuccessfulCardsList;
export default cards.reducer;
export var fetchCardsList = function (inputs, limit, currentPage) {
    return apiCallBegan({
        url: "/admin/employee_cards?limit=".concat(limit, "&search_key=").concat(inputs === null || inputs === void 0 ? void 0 : inputs.searchText, "&page=").concat(currentPage, "&department_id=").concat(inputs === null || inputs === void 0 ? void 0 : inputs.department_id, "&designation_id=").concat(inputs === null || inputs === void 0 ? void 0 : inputs.designation_id, "&status=").concat(inputs === null || inputs === void 0 ? void 0 : inputs.status, "&print_type=").concat(inputs === null || inputs === void 0 ? void 0 : inputs.print_type),
        method: 'GET',
        onStart: CardsListPending.type,
        onSuccess: SuccessfulCardsList.type,
        onError: creationFailure.type,
    });
};
export var fetchCardTemplates = function (limit, page, searchText) {
    return apiCallBegan({
        url: "/admin/card_template?limit=".concat(limit, "&search_key=").concat(searchText, "&page=").concat(page),
        method: 'GET',
        onStart: CardsListPending.type,
        onSuccess: SuccessfulList.type,
        onError: creationFailure.type,
    });
};
export var fetchCardDetails = function (id) {
    return apiCallBegan({
        url: "/admin/employee_cards/".concat(id),
        method: 'GET',
        onStart: CardsListPending.type,
        onSuccess: CardDetailsSuccess.type,
        onError: creationFailure.type,
    });
};
export var deleteCard = function (id) {
    return apiCallBegan({
        url: "/admin/employee_cards/".concat(id),
        method: 'DELETE',
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    });
};
