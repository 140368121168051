var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import rightArrowGrey from '../../../../assets/icons/rightArrowGrey.svg';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
var activeLink = "m-6 text-[#575ABC] font-semibold text-base cursor-pointer border-b-[3px] border-b-[#575ABC] pb-2 transition-all";
var inActiveLink = "m-6 text-[#AEB0CF] font-semibold text-base cursor-pointer hover:text-[#575ABC]\n                      border-b-[3px] border-b-transparent hover:border-b-[#575ABC] pb-2 transition-all dark:text-white";
var ViewDetails = function () {
    var navigate = useNavigate();
    var id = useParams().id;
    var navData = [
        {
            title: 'Card Information',
            path: "/cards/all_cards/".concat(id, "/card-info")
        },
        {
            title: 'Print Info',
            path: "/cards/all_cards/".concat(id, "/print-info")
        },
        {
            title: 'Logs Info',
            path: "/cards/all_cards/".concat(id, "/logs-info")
        },
    ];
    var handleViewRoute = function () {
        navigate('/cards/all_cards');
    };
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: 'bg-white w-full rounded-xl flex items-center px-4 dark:bg-[#27282D]' }, { children: [_jsx("div", __assign({ className: 'm-3 px-2' }, { children: _jsx("p", __assign({ onClick: handleViewRoute, className: 'cursor-pointer text-[#AEB0CF] font-medium text-sm' }, { children: "All Cards" })) })), _jsx("div", __assign({ className: 'm-3 px-2' }, { children: _jsx("img", { src: rightArrowGrey }) })), _jsx("div", __assign({ className: 'm-3 px-2' }, { children: _jsx("p", __assign({ className: 'text-[#27282D] font-semibold text-sm dark:text-white' }, { children: "View Details" })) }))] })), _jsx("div", __assign({ className: 'my-6' }, { children: navData.map(function (item, index) { return (_jsx(NavLink, __assign({ to: item === null || item === void 0 ? void 0 : item.path, className: function (_a) {
                        var isActive = _a.isActive;
                        return (isActive ? activeLink : inActiveLink);
                    } }, { children: item === null || item === void 0 ? void 0 : item.title }))); }) }))] }));
};
export default ViewDetails;
