var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Logo from '../../../assets/images/logo.svg';
import CustomButton from '../../common/Button';
import { Input } from '../../common/input/Input';
import arrowLeftGrey from '../../../assets/icons/arrowLeftGrey.svg';
import { forgetPassword, resetapiSuccess } from '../../../features/auth/authSlice';
import Validator from 'validatorjs';
function ForgetPassword() {
    var _a, _b;
    var dispatch = useDispatch();
    var _c = useState(''), email = _c[0], setEmail = _c[1];
    var _d = useState({ email: '' }), formErrors = _d[0], setFormErrors = _d[1];
    var _e = useSelector(function (state) { return state.auth; }), errors = _e.errors, apiSuccess = _e.apiSuccess, isLoading = _e.isLoading;
    var _f = useState(false), success = _f[0], setSuccess = _f[1];
    useEffect(function () { return function () {
        dispatch(resetapiSuccess());
    }; }, []);
    function handleChange(e) {
        if (e.currentTarget.value.includes(' ')) {
            e.currentTarget.value = e.currentTarget.value.replace(/\s/g, '');
        }
        setEmail(e.target.value);
        setFormErrors({ email: '' });
    }
    function forgot(e) {
        return __awaiter(this, void 0, void 0, function () {
            var validation, fieldErrors_1;
            return __generator(this, function (_a) {
                e.preventDefault();
                validation = new Validator({
                    email: email,
                }, {
                    email: 'email|required|max:150',
                }, {
                    required: '*required',
                });
                if (validation.fails()) {
                    fieldErrors_1 = {};
                    Object.keys(validation.errors.errors).forEach(function (key) {
                        fieldErrors_1[key] = validation.errors.errors[key][0];
                    });
                    setFormErrors(fieldErrors_1);
                    return [2 /*return*/, false];
                }
                dispatch(forgetPassword({ email: email }));
                return [2 /*return*/, true];
            });
        });
    }
    // if (isUserLoggedIn()) {
    //     return <Navigate to='/dashboard' />;
    // }
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: 'login-bg bg-[#575ABC] w-full h-screen' }, { children: _jsxs("div", __assign({ className: 'flex px-16 py-12' }, { children: [_jsx("div", { className: 'w-1/2' }), _jsxs("div", __assign({ className: 'w-1/2  max-w-lg\t m-auto mt-8 flex justify-center items-center  pb-6 rounded-3xl bg-white flex-col gap-4' }, { children: [_jsx("div", __assign({ className: 'pt-4' }, { children: _jsx("img", { src: Logo, alt: 'logo' }) })), _jsxs("div", __assign({ className: 'flex flex-col justify-center items-center gap-6' }, { children: [' ', _jsx("h2", __assign({ className: 'mt-4 text-2xl font-extrabold text-[#575ABC]' }, { children: "Forget Your Password" })), _jsx("p", __assign({ className: 'text-[#27282D] px-10 py-2 font-bold text-sm text-center' }, { children: "Enter Your Email and we will send you the Instructions to Reset the Password." }))] })), _jsxs("form", __assign({ className: 'w-full', onSubmit: forgot }, { children: [_jsxs("div", __assign({ className: 'w-full px-10' }, { children: [_jsx("label", __assign({ className: 'mb-1  block text-xs font-bold text-[#27282D]', htmlFor: 'email' }, { children: "Email ID" })), _jsx(Input, { placeholder: 'Enter your email ID', bg: 'dark:bg-transparent bg-[#F5F8FF]', greyBorder: 'dark:border dark:border-[#AEB0CF] border-0', value: email, handleChange: handleChange, name: 'email', type: 'email', error: !!(formErrors === null || formErrors === void 0 ? void 0 : formErrors.email), helperText: ((_a = formErrors === null || formErrors === void 0 ? void 0 : formErrors.email) === null || _a === void 0 ? void 0 : _a.includes('required'))
                                                    ? formErrors === null || formErrors === void 0 ? void 0 : formErrors.email
                                                    : ((_b = formErrors === null || formErrors === void 0 ? void 0 : formErrors.email) === null || _b === void 0 ? void 0 : _b.includes('format'))
                                                        ? formErrors === null || formErrors === void 0 ? void 0 : formErrors.email
                                                        : '' })] })), _jsxs("div", __assign({ className: 'w-full flex flex-col gap-4 px-10' }, { children: [_jsx("div", __assign({ className: '' }, { children: _jsx(CustomButton, __assign({ type: 'submit', onClick: forgot, disabled: isLoading }, { children: "Send Reset Link" })) })), _jsxs("div", __assign({ className: 'mx-auto flex items-center justify-center border-b-2 border-[#27282D80]' }, { children: [_jsx("img", { className: 'mr-1', src: arrowLeftGrey, alt: '' }), _jsx(Link, __assign({ to: '/login', className: 'text-[#27282D80] text-xs mt-1' }, { children: "Back to Login" }))] }))] }))] }))] }))] })) })) }));
}
export default ForgetPassword;
