var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { showToastMessage } from './../../utils/helpers';
var initialState = {
    userRoles: {},
    isLoading: false,
    isCreatePending: false,
    isCreateSuccess: false,
    apiSuccess: false,
    userRolesList: [],
    allModules: [],
    metadata: {
        totalUserRoles: 0,
        totalPages: 0,
    },
    updateApiSuccess: false,
};
export var userRoles = createSlice({
    name: 'userRole',
    initialState: initialState,
    reducers: {
        formReset: function () { },
        resetLoading: function (state) {
            state.isLoading = true;
        },
        CreatePending: function (state) {
            state.isCreatePending = true;
        },
        creationSuccessful: function (state, action) {
            // console.log('action:', action.payload)
            state.isCreatePending = false;
            state.isCreateSuccess = true;
            showToastMessage(action.payload.data.message, 'success');
        },
        resetApiSuccess: function (state) {
            state.isCreateSuccess = false;
            // console.log('dfghjk')
        },
        creationFailure: function (state, action) {
            state.isLoading = false;
            state.apiSuccess = false;
            state.isCreatePending = false;
            showToastMessage(action.payload, 'error');
        },
        fetchSuccessful: function (state, action) {
            //   state.cards = action.payload;
        },
        updateSuccessful: function (state) {
            state.isLoading = false;
            state.updateApiSuccess = true;
        },
        updateApi: function (state) {
            state.updateApiSuccess = false;
        },
        SuccessfulList: function (state, action) {
            // console.log(action.payload.data.modules, 'modules');
            state.userRolesList = action.payload.data.data.data;
            state.allModules = action.payload.data.modules;
            state.metadata.totalPages = action.payload.data.data.meta.last_page;
            state.metadata.totalUserRoles = action.payload.data.data.meta.total;
            state.isLoading = false;
            state.apiSuccess = true;
        },
        UserRolesListPending: function (state) {
            state.isLoading = true;
        },
    },
});
export var creationSuccessful = (_a = userRoles.actions, _a.creationSuccessful), creationFailure = _a.creationFailure, formReset = _a.formReset, resetLoading = _a.resetLoading, resetApiSuccess = _a.resetApiSuccess, updateSuccessful = _a.updateSuccessful, updateApi = _a.updateApi, SuccessfulList = _a.SuccessfulList, UserRolesListPending = _a.UserRolesListPending, fetchSuccessful = _a.fetchSuccessful, CreatePending = _a.CreatePending;
export default userRoles.reducer;
export var fetchUserRoles = function (limit, page, searchText) {
    return apiCallBegan({
        url: "/admin/role?limit=".concat(limit, "&search_key=").concat(searchText, "&page=").concat(page),
        method: 'GET',
        onStart: UserRolesListPending.type,
        onSuccess: SuccessfulList.type,
        onError: creationFailure.type,
    });
};
//   export const addDepartment = (data: any) =>
//     apiCallBegan({
//         url: '/admin/department',
//         method: 'POST',
//         data,
//         onStart: CreatePending.type,
//         onSuccess: creationSuccessful.type,
//         onError: creationFailure.type,
//     }) 
export var deleteUserRole = function (id) {
    return apiCallBegan({
        url: "/admin/role/".concat(id),
        method: 'DELETE',
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    });
};
