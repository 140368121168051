var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Provider } from 'react-redux';
import './index.scss';
import { store } from './store';
import { Layout } from './layout/Layout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorBoundary } from './components/ErrorBoundry';
var OurFallbackComponent = function (_a) {
    var error = _a.error, componentStack = _a.componentStack, resetErrorBoundary = _a.resetErrorBoundary;
    return (_jsxs("div", { children: [_jsxs("h1", { children: ["An error occurred:", error.message] }), _jsxs("h1", { children: ["An error occurred:", componentStack] }), _jsx("button", __assign({ type: "button", onClick: resetErrorBoundary }, { children: "Try again" }))] }));
};
function App() {
    return (_jsx(Provider, __assign({ store: store }, { children: _jsxs(ErrorBoundary, __assign({ FallbackComponent: OurFallbackComponent }, { children: [_jsx(Layout, {}), _jsx(ToastContainer, {})] })) })));
}
export default App;
