var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import React from 'react';
var LogInfo = function (_a) {
    var params = _a.params;
    return (_jsxs("div", __assign({ className: '' }, { children: [params === null || params === void 0 ? void 0 : params.employeeView.logs.map(function (item) { return (_jsxs("div", __assign({ className: 'flex flex-row items-center bg-white rounded-xl mb-2 px-4 dark:bg-[#27282D] ' }, { children: [_jsx("p", __assign({ className: 'm-4 text-base text-[#27282D80] font-medium dark:text-white' }, { children: moment(item === null || item === void 0 ? void 0 : item.created_at).format('hh:mm a DD-MM-YYYY') })), _jsx("p", __assign({ className: 'm-4 text-base text-[#27282D] dark:text-white font-medium' }, { children: item === null || item === void 0 ? void 0 : item.message }))] }))); }), _jsx("div", __assign({ className: 'mt-5 text-center' }, { children: _jsx("button", __assign({ className: 'bg-[#D8D9F17A] text-[#27282D] text-base py-2 px-4 rounded-xl dark:text-white' }, { children: "Show More" })) }))] })));
};
export default LogInfo;
