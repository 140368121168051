var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var Stepper = function (_a) {
    var info = _a.info;
    var steps = ['Personal Information', 'Official Information'];
    return (_jsx("div", __assign({ className: 'flex justify-between w-full' }, { children: _jsx("div", __assign({ className: 'step-item flex flex-row  ' }, { children: _jsxs("div", __assign({ className: 'flex gap-2' }, { children: [_jsxs("div", __assign({ className: 'flex gap-2' }, { children: [_jsx("div", __assign({ className: "w-8 h-8 rounded-full flex items-center justify-center text-center ".concat(info && 'bg-[#FFD981]') }, { children: "1" })), _jsx("div", __assign({ className: 'text-white mt-1' }, { children: "Personal Information" }))] })), _jsx("div", { className: 'w-10 bg-[#AEB0CF] h-[1px] flex justify-center mt-4 mx-2' }), _jsx("div", __assign({ className: "w-8 h-8 rounded-full flex items-center justify-center ".concat(info == 2 ? 'bg-[#FFD981]' : 'bg-[#8A8DF6]') }, { children: _jsx("span", __assign({ className: "".concat(info == 2 ? 'text-[#27282D]' : 'text-white') }, { children: "2" })) })), _jsx("div", __assign({ className: 'text-white mt-1' }, { children: "Official Information" }))] })) })) })));
};
export default Stepper;
