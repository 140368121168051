import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
function Chart(departmentCount) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var options = {
        responsive: true,
        barThickness: 50,
        barPercentage: 4,
        chartArea: {
            backgroundColor: 'rgba(255,255,255)',
        },
        scales: {
            x: {
                border: {
                    display: false,
                },
                grid: {
                    display: false,
                },
                min: 0,
                max: 100,
                ticks: {
                    autoSkip: false,
                    padding: 2,
                    font: {
                        size: 10
                    }
                },
            },
            y: {
                border: {
                    display: false,
                },
                grid: {
                    display: false,
                },
                min: 0,
                max: 100,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                anchor: 'end',
                align: '-90',
                color: ['#FFD981', '#575ABC', '#FFD981', '#575ABC'],
                font: {
                    weight: 'bold',
                    size: 14
                },
            }
        },
    };
    var data = {
        labels: [['Total', 'Employees'], ['Cards', 'Distributed'], ['New', 'Cards', 'Requested'], ['Cards', 'Inprogress']],
        borderColor: '#fffff',
        datasets: [
            {
                data: [
                    (_b = (_a = departmentCount === null || departmentCount === void 0 ? void 0 : departmentCount.departmentCount) === null || _a === void 0 ? void 0 : _a.employees) === null || _b === void 0 ? void 0 : _b.total,
                    (_d = (_c = departmentCount === null || departmentCount === void 0 ? void 0 : departmentCount.departmentCount) === null || _c === void 0 ? void 0 : _c.distributed) === null || _d === void 0 ? void 0 : _d.total,
                    (_f = (_e = departmentCount === null || departmentCount === void 0 ? void 0 : departmentCount.departmentCount) === null || _e === void 0 ? void 0 : _e.requested) === null || _f === void 0 ? void 0 : _f.total,
                    (_h = (_g = departmentCount === null || departmentCount === void 0 ? void 0 : departmentCount.departmentCount) === null || _g === void 0 ? void 0 : _g.inprogress) === null || _h === void 0 ? void 0 : _h.total,
                ],
                backgroundColor: ['#FFD981', '#575ABC', '#FFD981', '#575ABC'],
                borderRadius: 8,
            },
        ],
    };
    return (_jsx(_Fragment, { children: _jsx("div", { children: _jsx(Bar, { options: options, data: data }) }) }));
}
export default Chart;
