var _a;
import { showToastMessage } from './../../utils/helpers';
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
var initialState = {
    employeeView: {},
    employeeStatistics: {},
    isLoading: false,
    isCreateSuccessfull: false,
    apiSuccess: false,
};
export var employees = createSlice({
    name: 'employees',
    initialState: initialState,
    reducers: {
        formReset: function () { },
        employeeViewSuccess: function (state, action) {
            state.employeeView = action.payload.data.data;
            state.apiSuccess = true;
            state.isLoading = false;
        },
        employeeStatisticsSuccess: function (state, action) {
            state.employeeStatistics = action.payload.data;
        },
        fetchFailure: function (state, action) {
            state.isLoading = false;
            showToastMessage(action.payload.message, 'error');
        },
        ListPending: function (state) {
            state.isLoading = true;
        },
    },
});
export var employeeViewSuccess = (_a = employees.actions, _a.employeeViewSuccess), ListPending = _a.ListPending, fetchFailure = _a.fetchFailure, employeeStatisticsSuccess = _a.employeeStatisticsSuccess;
export default employees.reducer;
export var employeeViewDetails = function (id) {
    return apiCallBegan({
        url: "/admin/employee/".concat(id),
        method: 'GET',
        onStart: ListPending.type,
        onSuccess: employeeViewSuccess.type,
        onError: fetchFailure.type,
    });
};
export var employeeStatisticDetails = function () {
    return apiCallBegan({
        url: '/admin/employee_stats',
        method: 'GET',
        onStart: ListPending.type,
        onSuccess: employeeStatisticsSuccess.type,
        onError: fetchFailure.type,
    });
};
