var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import CustomButton from '../../common/Button';
import Plus from '../../../assets/icons/Plus.svg';
import AddDepartment from './Departments/AddDepartment';
import AddDesignation from './Designations/AddDesignation';
import AddNewRank from './Ranks/AddNewRank';
import AddNewUserRole from './UserRole/AddNewUserRole';
import { NavLink, useLocation } from 'react-router-dom';
var activeLink = "m-6 text-[#575ABC] font-semibold text-base cursor-pointer border-b-[3px] border-b-[#575ABC] pb-2 transition-all";
var inActiveLink = "m-6 text-[#AEB0CF] font-semibold text-base cursor-pointer hover:text-[#575ABC]\n                      border-b-[3px] border-b-transparent hover:border-b-[#575ABC] pb-2 transition-all dark:text-white";
var navData = [
    {
        title: 'Department',
        path: '/settings/departments'
    },
    {
        title: 'Designation',
        path: '/settings/designations'
    },
    {
        title: 'Ranks',
        path: '/settings/ranks'
    },
    {
        title: 'User Role',
        path: '/settings/user-role'
    },
];
var Settings = function (_a) {
    var handleChildChange = _a.handleChildChange, handleRootChange = _a.handleRootChange, params = _a.params, handleChange = _a.handleChange, submit = _a.submit, formErrors = _a.formErrors, errors = _a.errors, success = _a.success, addNewRole = _a.addNewRole, fetchdepartments = _a.fetchdepartments, successApi = _a.successApi;
    var _b = useState(false), modalIsOpen = _b[0], setIsOpen = _b[1];
    var location = useLocation();
    var pathname = location.pathname;
    useEffect(function () {
    }, [location]);
    function openModal() {
        pathname.includes('user-role') ? addNewRole() : '';
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    useEffect(function () {
        if (successApi === false)
            closeModal();
    }, [successApi]);
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: 'flex flex-row items-center' }, { children: [_jsx("div", { className: 'w-[12px] h-[20px] bg-[#575ABC] rounded-lg mr-2' }), _jsx("div", __assign({ className: 'text-2xl text-[#27282D] font-bold dark:text-white' }, { children: "Settings" }))] })), _jsx("div", __assign({ className: ' mt-5' }, { children: _jsxs("div", __assign({ className: 'ml-4 flex flex-row justify-between items-center' }, { children: [_jsx("div", { children: navData.map(function (item, index) { return (_jsx(NavLink, __assign({ to: item === null || item === void 0 ? void 0 : item.path, className: function (_a) {
                                    var isActive = _a.isActive;
                                    return (isActive ? activeLink : inActiveLink);
                                } }, { children: item === null || item === void 0 ? void 0 : item.title }))); }) }), _jsxs("div", __assign({ className: '' }, { children: [_jsx(CustomButton, __assign({ icon: Plus, onClick: openModal }, { children: pathname.includes('department') ?
                                        _jsx("p", { children: "Add New Department" }) :
                                        pathname.includes('designation') ?
                                            _jsx("p", { children: "Add New Designation" }) :
                                            pathname.includes('rank') ?
                                                _jsx("p", { children: "Add New Rank" }) :
                                                pathname.includes('user-role') ?
                                                    _jsx("p", { children: "Add New User Role" }) : '' })), modalIsOpen && pathname.includes('department') ?
                                    _jsx(AddDepartment, { modalIsOpen: modalIsOpen, closeModal: closeModal, fetchdepartments: fetchdepartments }) :
                                    modalIsOpen && pathname.includes('designation') ?
                                        _jsx(AddDesignation, { modalIsOpen: modalIsOpen, openModal: openModal, closeModal: closeModal }) :
                                        modalIsOpen && pathname.includes('rank') ?
                                            _jsx(AddNewRank, { modalIsOpen: modalIsOpen, openModal: openModal, closeModal: closeModal }) :
                                            modalIsOpen && pathname.includes('user-role') ?
                                                _jsx(AddNewUserRole, { modalIsOpen: modalIsOpen, openModal: openModal, closeModal: closeModal, handleChildChange: handleChildChange, handleRootChange: handleRootChange, params: params, handleChange: handleChange, submit: submit, formErrors: formErrors, errors: errors, success: success }) : ''] }))] })) }))] }));
};
export default Settings;
