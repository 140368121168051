var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
export var SelectInput = function (_a) {
    var handleChange = _a.handleChange, value = _a.value, label = _a.label, icon = _a.icon, error = _a.error, helperText = _a.helperText, options = _a.options, width = _a.width, name = _a.name, required = _a.required, readonly = _a.readonly, greyBorder = _a.greyBorder, height = _a.height, bg = _a.bg, defaultValue = _a.defaultValue;
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'relative flex w-full flex-wrap items-stretch mb-3' }, { children: [_jsx("span", __assign({ className: 'absolute top-[18px] left-4' }, { children: _jsx("img", { src: icon, alt: '' }) })), _jsxs("select", __assign({ id: 'countries', className: "".concat(width ? width : 'w-full', " ").concat(!height ? 'h-[54px]' : height, "\n\n                    ").concat(!greyBorder ? 'border border-transparent' : greyBorder, "\n                    ").concat(error ? 'input-error dark:border dark:border-[#ef4949]' : '', "\n                hover:border-[1px] hover:border-[#AEB0CF] ").concat(!bg ? 'bg-[#F5F8FF]' : bg, "\n                text-sm rounded-lg focus:border-[1px] focus:border-[#AEB0CF] text-[#43454A] dark:text-white\n                block w-full outline-none ").concat(!icon ? 'px-2' : 'px-9'), required: required, value: value, onChange: handleChange, name: name, defaultValue: defaultValue }, { children: [_jsx("option", __assign({ className: '' }, { children: label })), (options === null || options === void 0 ? void 0 : options.length) ? (options === null || options === void 0 ? void 0 : options.map(function (item) {
                            return (_jsx("option", __assign({ className: 'bg-[#F5F8FF] text-[#27282D] hover:text-[#575ABC]', value: item.value || item.id }, { children: (item === null || item === void 0 ? void 0 : item.value) ||
                                    (item === null || item === void 0 ? void 0 : item.name) ||
                                    (item === null || item === void 0 ? void 0 : item.first_name) ||
                                    (item === null || item === void 0 ? void 0 : item.rank_value) ||
                                    (item === null || item === void 0 ? void 0 : item.department_name) ||
                                    (item === null || item === void 0 ? void 0 : item.designation) ||
                                    (item === null || item === void 0 ? void 0 : item.status) }), item.id));
                        })) : (_jsx("p", __assign({ className: 'text-white p-4 text-xl' }, { children: "Not found !" })))] })), helperText ? _jsx("p", __assign({ className: "text-error text-xs" }, { children: helperText })) : ''] })) }));
};
