var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reducer from "./features/reducers";
import api from "./features/middleware/api";
export var store = configureStore({
    reducer: reducer,
    middleware: __spreadArray(__spreadArray([], getDefaultMiddleware({ serializableCheck: false }), true), [
        api
    ], false)
});
