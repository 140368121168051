var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { encryptData, decryptData } from '../../../utils/encryption';
import { useSelector, useDispatch } from 'react-redux';
import { formReset, login } from '../../../features/auth/authSlice';
import Logo from '../../../assets/images/logo.svg';
import CustomButton from '../../common/Button';
import { Input } from '../../common/input/Input';
import { CustomCheckbox } from '../../common/Checkbox';
import { rememberMeToken } from '../../../utils/auth';
import Validator from 'validatorjs';
var initialParamsState = {
    email: '',
    password: '',
};
function Login() {
    var _this = this;
    var _a, _b;
    var dispatch = useDispatch();
    // const { errors } = useSelector((state: any) => state.auth);
    var _c = useState(initialParamsState), params = _c[0], setParams = _c[1];
    var navigate = useNavigate();
    var _d = useSelector(function (state) { return state.auth; }), user = _d.user, loginSuccess = _d.loginSuccess, isLoading = _d.isLoading, access_token = _d.access_token;
    var _e = useState(initialParamsState), formErrors = _e[0], setFormErrors = _e[1];
    var _f = useState(false), success = _f[0], setSuccess = _f[1];
    var _g = useState(false), remember_me = _g[0], setRememberMe = _g[1];
    var remember_me_token = rememberMeToken();
    useEffect(function () {
        if (remember_me_token) {
            var decrypt = decryptData(remember_me_token);
            if (decrypt) {
                var _a = decrypt.split('__'), email = _a[0], password = _a[1];
                if (email && password) {
                    setParams({ email: email, password: password });
                    setRememberMe(true);
                }
            }
        }
    }, []);
    useEffect(function () {
        if (Object.keys(user).length) {
            navigate('/dashboard');
        }
    }, [user]);
    useEffect(function () {
        if (access_token) {
            navigate("/update-password/".concat(access_token));
        }
    }, [access_token]);
    var handleChange = function (e) {
        var _a;
        if (e.currentTarget.value.includes(' ')) {
            e.currentTarget.value = e.currentTarget.value.replace(/\s/g, '');
        }
        formReset();
        setFormErrors(initialParamsState);
        setParams(__assign(__assign({}, params), (_a = {}, _a[e.target.name] = e.target.value, _a)));
    };
    var handleCheck = function (e) {
        var checked = e.target.checked;
        setRememberMe(checked);
    };
    var submit = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var validation, fieldErrors_1, remember_me_token_1;
        return __generator(this, function (_a) {
            e.preventDefault();
            validation = new Validator(params, {
                email: 'email|required',
                password: 'required|min:8|max:14',
            });
            if (validation.fails()) {
                fieldErrors_1 = {};
                Object.keys(validation.errors.errors).forEach(function (key) {
                    fieldErrors_1[key] = validation.errors.errors[key][0];
                });
                setFormErrors(fieldErrors_1);
                return [2 /*return*/, false];
            }
            if (remember_me) {
                remember_me_token_1 = encryptData("".concat(params.email, "__").concat(params.password));
                localStorage.setItem('remember_me_token', remember_me_token_1);
            }
            else {
                localStorage.removeItem('remember_me_token');
            }
            dispatch(login(params));
            setSuccess(!success);
            return [2 /*return*/, true];
        });
    }); };
    return (_jsx("div", __assign({ className: 'login-bg bg-[#575ABC] w-full h-screen' }, { children: _jsxs("div", __assign({ className: 'flex px-16 py-12' }, { children: [_jsx("div", { className: 'w-1/2' }), _jsxs("div", __assign({ className: 'w-1/2  max-w-lg\t m-auto mt-8 flex justify-center items-center  pb-6 rounded-3xl bg-white flex-col gap-4' }, { children: [_jsx("div", __assign({ className: 'pt-4' }, { children: _jsx("img", { src: Logo, alt: 'logo' }) })), _jsxs("div", { children: [' ', _jsx("h2", __assign({ className: 'mt-4 text-2xl font-extrabold text-[#575ABC]' }, { children: "Login to GOG Admin Panel" }))] }), _jsxs("form", __assign({ onSubmit: submit, className: 'w-full' }, { children: [_jsxs("div", __assign({ className: 'w-full px-10' }, { children: [_jsx("label", __assign({ className: 'mb-1  block text-xs font-bold text-[#27282D]', htmlFor: 'email' }, { children: "Email ID" })), _jsx(Input, { placeholder: 'Enter your email ID', bg: 'dark:bg-transparent bg-[#F5F8FF]', greyBorder: 'dark:border dark:border-[#AEB0CF] border-0', value: params === null || params === void 0 ? void 0 : params.email, handleChange: handleChange, name: 'email', type: 'email', error: !!(formErrors === null || formErrors === void 0 ? void 0 : formErrors.email), helperText: ((_a = formErrors === null || formErrors === void 0 ? void 0 : formErrors.email) === null || _a === void 0 ? void 0 : _a.includes('required'))
                                                ? formErrors === null || formErrors === void 0 ? void 0 : formErrors.email
                                                : ((_b = formErrors === null || formErrors === void 0 ? void 0 : formErrors.email) === null || _b === void 0 ? void 0 : _b.includes('format'))
                                                    ? formErrors === null || formErrors === void 0 ? void 0 : formErrors.email
                                                    : '' }), _jsx("br", {}), _jsx("label", __assign({ className: 'mb-1  block text-xs font-bold text-[#27282D]', htmlFor: 'password' }, { children: "Password" })), _jsx(Input, { placeholder: 'Enter your password', bg: 'dark:bg-transparent bg-[#F5F8FF]', greyBorder: 'dark:border dark:border-[#AEB0CF] border-0', value: params === null || params === void 0 ? void 0 : params.password, handleChange: handleChange, name: 'password', type: 'password', error: !!(formErrors === null || formErrors === void 0 ? void 0 : formErrors.password), helperText: formErrors.password })] })), _jsxs("div", __assign({ className: 'w-full flex flex-row justify-between px-10' }, { children: [_jsxs("div", __assign({ className: 'flex gap-1 justify-center items-center' }, { children: [_jsx(CustomCheckbox, { handleCheck: handleCheck, ischecked: remember_me, name: 'remember' }), _jsx("label", __assign({ className: 'text-sm text-[#27282D80]' }, { children: "Remember me" }))] })), _jsx("div", __assign({ className: 'flex lg:items-center text-sm text-[#27282D80] border-b-2 border-[#27282D80]' }, { children: _jsx(Link, __assign({ className: '', to: '/forget-password' }, { children: "Forgot Your Password ?" })) }))] })), _jsx("div", __assign({ className: 'w-full  mt-6 px-10' }, { children: _jsx(CustomButton, __assign({ type: 'submit', onClick: submit, disabled: isLoading }, { children: "Login" })) }))] }))] }))] })) })));
}
export default Login;
