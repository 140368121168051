var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { showToastMessage } from '../../utils/helpers';
var initialState = {
    designation: {},
    isLoading: false,
    isCreatePending: false,
    isCreateSuccess: false,
    apiSuccess: false,
    designationList: [],
    metadata: {
        totalDesignations: 0,
        totalPages: 0,
    },
    updateApiSuccess: false,
};
export var designations = createSlice({
    name: 'designation',
    initialState: initialState,
    reducers: {
        formReset: function () { },
        resetLoading: function (state) {
            state.isLoading = true;
        },
        CreatePending: function (state) {
            state.isCreatePending = true;
        },
        creationSuccessful: function (state, action) {
            state.isCreatePending = false;
            state.isCreateSuccess = true;
            showToastMessage(action.payload.data.message, 'success');
        },
        resetApiSuccess: function (state) {
            state.isCreateSuccess = false;
        },
        creationFailure: function (state, action) {
            state.isLoading = false;
            state.apiSuccess = false;
            state.isCreatePending = false;
            showToastMessage(action.payload, 'error');
        },
        updateSuccessful: function (state) {
            state.isLoading = false;
            state.updateApiSuccess = true;
        },
        updateApi: function (state) {
            state.updateApiSuccess = false;
        },
        SuccessfulList: function (state, action) {
            state.designationList = action.payload.data.data.data;
            state.metadata.totalPages = action.payload.data.data.meta.last_page;
            state.metadata.totalDesignations = action.payload.data.data.meta.total;
            state.isLoading = false;
            state.apiSuccess = true;
        },
        DesignationListPending: function (state) {
            state.isLoading = true;
        },
    },
});
export var creationSuccessful = (_a = designations.actions, _a.creationSuccessful), creationFailure = _a.creationFailure, formReset = _a.formReset, resetLoading = _a.resetLoading, resetApiSuccess = _a.resetApiSuccess, updateSuccessful = _a.updateSuccessful, updateApi = _a.updateApi, SuccessfulList = _a.SuccessfulList, DesignationListPending = _a.DesignationListPending, CreatePending = _a.CreatePending;
export default designations.reducer;
export var fetchDesignations = function (limit, page, searchText) {
    return apiCallBegan({
        url: "/admin/designation?limit=".concat(limit, "&search_key=").concat(searchText, "&page=").concat(page),
        method: 'GET',
        onStart: DesignationListPending.type,
        onSuccess: SuccessfulList.type,
        onError: creationFailure.type,
    });
};
export var addDesignation = function (data) {
    return apiCallBegan({
        url: '/admin/designation',
        method: 'POST',
        data: data,
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    });
};
export var EditDesignation = function (data, id) {
    return apiCallBegan({
        url: "/admin/designation/".concat(id),
        method: 'PUT',
        data: data,
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    });
};
export var deleteDesignation = function (id) {
    return apiCallBegan({
        url: "/admin/designation/".concat(id),
        method: 'DELETE',
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    });
};
