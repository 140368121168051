var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../../common/Button';
import Pagination from '../../../common/Pagination/Pagination';
import SearchBar from '../../../common/SearchBar';
import Settings from '../Settings';
import BasicTable from './Table';
import { deleteUserRole, fetchUserRoles, resetApiSuccess } from '../../../../features/settings/userRoleSlice';
import axiosInstance from '../../../../utils/axios';
import { showToastMessage, validateAplphaNumeric } from '../../../../utils/helpers';
import Validator from 'validatorjs';
var col = [
    { title: 'RANK VALUE' },
    { title: 'USER ROLE' },
    { title: 'MODULES' },
    { title: 'ACTION' },
];
var data = [
    {
        id: 1,
        role: 'Admin Level 2',
        modules: 'ALL',
    },
    {
        id: 2,
        role: 'Admin Level 3',
        modules: 'ALL',
    },
    {
        id: 3,
        role: 'Departmental Admin Level 1',
        modules: 'Dashboard, Employees, Cards',
    },
];
var initialVal = {
    searchText: ''
};
var initial = {
    name: '',
    id: '',
    list: [],
};
var UserRole = function (_a) {
    var type = _a.type;
    var dispatch = useDispatch();
    var _b = useSelector(function (state) { return state.userRoles; }), userRolesList = _b.userRolesList, isLoading = _b.isLoading, metadata = _b.metadata, isCreateSuccess = _b.isCreateSuccess;
    var totalUserRoles = metadata.totalUserRoles, totalPages = metadata.totalPages;
    var _c = useState(1), currentPage = _c[0], setCurrentPage = _c[1];
    var _d = useState(initialVal), para = _d[0], setPara = _d[1];
    var _e = useState(10), limit = _e[0], setLimit = _e[1];
    var _f = useState(10), limitonChange = _f[0], setLimitonChange = _f[1];
    var _g = useState([]), updateData = _g[0], setUpdateData = _g[1];
    var _h = useState([]), rolesList = _h[0], setRolesList = _h[1];
    var _j = useState([]), modulesList = _j[0], setModulesList = _j[1];
    var _k = useState(initial), params = _k[0], setParams = _k[1];
    var _l = useState(false), open = _l[0], setOpen = _l[1];
    var pathname = location.pathname;
    var _m = useState({ name: '' }), formErrors = _m[0], setFormErrors = _m[1];
    var _o = useState(false), errors = _o[0], setErrors = _o[1];
    var _p = useState(false), success = _p[0], setSuccess = _p[1];
    var _q = useState(false), resetBtn = _q[0], setResetBtn = _q[1];
    var _r = useState(null), userId = _r[0], setUserID = _r[1];
    var _s = useState(true), successApi = _s[0], setSuccessApi = _s[1];
    var _t = useState(true), disableBtn = _t[0], setDisableBtn = _t[1];
    var handleSearch = function (event) {
        var _a;
        var _b = event.target, name = _b.name, value = _b.value;
        if (value.length === 1) {
            var isValid = validateAplphaNumeric(value);
            if (!isValid) {
                return;
            }
        }
        setDisableBtn(false);
        setPara(__assign(__assign({}, para), (_a = {}, _a[name] = value, _a)));
    };
    var handleSubmit = function () {
        setResetBtn(true);
        setCurrentPage(1);
        dispatch(fetchUserRoles(limit, 1, para.searchText));
    };
    var handleCancel = function () {
        setDisableBtn(true);
        setResetBtn(false);
        setPara(__assign(__assign({}, para), { searchText: '' }));
        setCurrentPage(1);
        dispatch(fetchUserRoles(limit, 1, ''));
    };
    useMemo(function () {
        dispatch(fetchUserRoles(limit, currentPage, para.searchText));
    }, [currentPage]);
    useEffect(function () {
    }, [location]);
    var deleteRole = function (id) {
        dispatch(deleteUserRole(id));
    };
    var createRole = function (payload) {
        axiosInstance
            .post("/admin/role", payload)
            .then(function (res) {
            setSuccessApi(false);
            showToastMessage('User Role Created Successfully', 'success');
            dispatch(fetchUserRoles(10, 1, ''));
        })
            .catch(function (error) {
            showToastMessage(error.response.data.message, 'error');
        });
    };
    var onRowEdit = function (row) {
        var options = __spreadArray([], modulesList, true);
        options = options
            .filter(function (x) { return x.parent_id == null; })
            .map(function (x) {
            var root_ele = row.permissions.find(function (y) { return y.module_id === x.id; });
            x['is_checked'] =
                (root_ele === null || root_ele === void 0 ? void 0 : root_ele.is_read) ||
                    (root_ele === null || root_ele === void 0 ? void 0 : root_ele.is_write) ||
                    (root_ele === null || root_ele === void 0 ? void 0 : root_ele.is_delete) ||
                    (root_ele === null || root_ele === void 0 ? void 0 : root_ele.is_update) ||
                    false;
            x['is_read'] = (root_ele === null || root_ele === void 0 ? void 0 : root_ele.is_read) || false;
            x['is_write'] = (root_ele === null || root_ele === void 0 ? void 0 : root_ele.is_write) || false;
            x['is_delete'] = (root_ele === null || root_ele === void 0 ? void 0 : root_ele.is_delete) || false;
            x['is_update'] = (root_ele === null || root_ele === void 0 ? void 0 : root_ele.is_update) || false;
            x['children'] = modulesList
                .filter(function (y) { return y.parent_id === x.id; })
                .map(function (z) {
                var child_ele = row.permissions.find(function (y) { return y.module_id === z.id; });
                z['is_checked'] =
                    (child_ele === null || child_ele === void 0 ? void 0 : child_ele.is_read) ||
                        (child_ele === null || child_ele === void 0 ? void 0 : child_ele.is_write) ||
                        (child_ele === null || child_ele === void 0 ? void 0 : child_ele.is_delete) ||
                        (child_ele === null || child_ele === void 0 ? void 0 : child_ele.is_update) ||
                        false;
                z['is_read'] = (child_ele === null || child_ele === void 0 ? void 0 : child_ele.is_read) || false;
                z['is_write'] = (child_ele === null || child_ele === void 0 ? void 0 : child_ele.is_write) || false;
                z['is_delete'] = (child_ele === null || child_ele === void 0 ? void 0 : child_ele.is_delete) || false;
                z['is_update'] = (child_ele === null || child_ele === void 0 ? void 0 : child_ele.is_update) || false;
                return z;
            });
            return x;
        });
        setParams(__assign(__assign({}, params), { list: options, name: row.name, id: row.id }));
    };
    var updateRole = function (id, payload) {
        axiosInstance
            .put("/admin/role/" + id, payload)
            .then(function (res) {
            setOpen(false);
            showToastMessage('Role Updated Successfully', 'success');
            dispatch(fetchUserRoles(10, 1, ''));
        })
            .catch(function (error) {
            var message = error.response.data.errors.message;
            showToastMessage(message, 'error');
        });
    };
    var addPermission = function (array, list) {
        array.push({
            module_id: list.id,
            is_read: list.is_read,
            is_write: list.is_write,
            is_update: list.is_update,
            is_delete: list.is_delete,
        });
        return array;
    };
    var submit = function () {
        var rules = {
            name: ['required', 'regex:^[A-Za-zs]$', 'max:30'],
        };
        var validation = new Validator(params, rules);
        if (validation.fails()) {
            var fieldErrors_1 = {};
            Object.keys(validation.errors.errors).forEach(function (key) {
                fieldErrors_1[key] = validation.errors.errors[key][0];
            });
            var err = Object.keys(fieldErrors_1);
            if (err.length) {
                var input = document.querySelector("input[name=".concat(err[0], "]"));
                if (input) {
                    input.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'start',
                    });
                }
            }
            setErrors(!errors);
            setFormErrors(fieldErrors_1);
            return false;
        }
        var is_checked = params.list.some(function (x) { return x.is_checked; });
        if (!is_checked) {
            showToastMessage('Please Select atleast one module!', 'error');
            return;
        }
        var permissions = [];
        for (var _i = 0, _a = params.list; _i < _a.length; _i++) {
            var list = _a[_i];
            if (list.is_checked) {
                permissions = addPermission(permissions, list);
                var children = list.children.filter(function (x) { return x.is_checked; });
                if (children.length) {
                    for (var _b = 0, children_1 = children; _b < children_1.length; _b++) {
                        var child = children_1[_b];
                        permissions = addPermission(permissions, child);
                    }
                }
            }
        }
        var payload = {
            name: params.name,
            permissions: permissions,
        };
        if (params.id) {
            updateRole(params.id, payload);
        }
        else {
            createRole(payload);
        }
        setSuccess(!success);
        return;
    };
    var handleNestedCheckBoxSel = function (list, index, checked, child) {
        var subIndex = list[index].children.findIndex(function (x) { return x.id === child.id; });
        if (subIndex != -1) {
            list[index].children[subIndex].is_checked = checked;
            list[index].children[subIndex]['is_write'] = checked;
            list[index].children[subIndex]['is_update'] = checked;
            list[index].children[subIndex]['is_read'] = checked;
            list[index].children[subIndex]['is_delete'] = checked;
        }
        var is_child_checked = list[index].children.some(function (x) { return x.is_checked; });
        list[index].is_checked = is_child_checked;
    };
    var handleChildChange = function (e, item, child) {
        var _a = e.target, name = _a.name, checked = _a.checked;
        var list = __spreadArray([], params.list, true);
        var index = list.findIndex(function (x) { return x.id == item.id; });
        if (index != -1) {
            if (checked && ['is_write', 'is_update', 'is_read', 'is_delete'].includes(name)) {
                list[index].is_checked = checked;
                var subIndex = list[index].children.findIndex(function (x) { return x.id === child.id; });
                if (subIndex != -1) {
                    list[index].children[subIndex].is_checked = checked;
                    list[index].children[subIndex][name] = checked;
                }
            }
            else if (checked) {
                handleNestedCheckBoxSel(list, index, checked, child);
            }
            if (!checked && ['is_write', 'is_update', 'is_read', 'is_delete'].includes(name)) {
                var subIndex = list[index].children.findIndex(function (x) { return x.id === child.id; });
                if (subIndex != -1) {
                    list[index].children[subIndex][name] = checked;
                    var item_1 = list[index].children[subIndex];
                    if (!item_1.is_write && !item_1.is_update && !item_1.is_read && !item_1.is_delete) {
                        list[index].children[subIndex].is_checked = checked;
                    }
                    var is_child_checked = list[index].children.some(function (x) { return x.is_checked; });
                    list[index].is_checked = is_child_checked;
                }
            }
            if (!checked && !['is_write', 'is_update', 'is_read', 'is_delete'].includes(name)) {
                handleNestedCheckBoxSel(list, index, checked, child);
            }
        }
        setParams(__assign(__assign({}, params), { list: list }));
    };
    var handleCheckBoxSel = function (list, index, checked) {
        list[index].is_checked = checked;
        list[index]['is_write'] = checked;
        list[index]['is_update'] = checked;
        list[index]['is_read'] = checked;
        list[index]['is_delete'] = checked;
        list[index].children = list[index].children.map(function (x) {
            x['is_checked'] = checked;
            x['is_write'] = checked;
            x['is_update'] = checked;
            x['is_read'] = checked;
            x['is_delete'] = checked;
            return x;
        });
    };
    var handleRootChange = function (e, item) {
        var _a = e.target, name = _a.name, checked = _a.checked;
        var list = __spreadArray([], params.list, true);
        var index = list.findIndex(function (x) { return x.id == item.id; });
        if (index != -1) {
            if (checked && ['is_write', 'is_update', 'is_read', 'is_delete'].includes(name)) {
                list[index].is_checked = checked;
                list[index][name] = checked;
            }
            else if (checked) {
                handleCheckBoxSel(list, index, checked);
            }
            if (!checked && ['is_write', 'is_update', 'is_read', 'is_delete'].includes(name)) {
                list[index][name] = checked;
                var item_2 = list[index];
                if (!item_2.is_write && !item_2.is_update && !item_2.is_read && !item_2.is_delete) {
                    list[index].is_checked = checked;
                }
            }
            if (!checked && !['is_write', 'is_update', 'is_read', 'is_delete'].includes(name)) {
                handleCheckBoxSel(list, index, checked);
            }
        }
        setParams(__assign(__assign({}, params), { list: list }));
    };
    var addNewRole = function () {
        var options = __spreadArray([], modulesList, true);
        options = options.filter(function (x) { return x.parent_id == null; })
            .map(function (x) {
            x['is_checked'] = false;
            x['children'] = modulesList
                .filter(function (y) { return y.parent_id === x.id; })
                .map(function (z) {
                z['is_read'] = false;
                z['is_write'] = false;
                z['is_delete'] = false;
                z['is_update'] = false;
                z['is_checked'] = false;
                return z;
            });
            return x;
        });
        setParams(__assign(__assign({}, params), { name: '', id: '', list: options }));
        // console.log(options, params)
    };
    var handleChange = function (e) {
        var _a;
        setParams(__assign(__assign({}, params), (_a = {}, _a[e.target.name] = e.target.value, _a)));
    };
    var fecthRoles = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            axiosInstance
                .get("admin/role?search_key=".concat('', "&page=").concat(currentPage))
                .then(function (response) {
                var data = response.data.data.data;
                var modules = response.data.modules;
                setRolesList(data);
                setModulesList(modules);
            })
                .catch(function (error) {
                console.log('error:', error);
            });
            return [2 /*return*/];
        });
    }); };
    useMemo(function () {
        fecthRoles();
    }, [currentPage]);
    function openEditModal() {
        setOpen(true);
    }
    function closeEditModal() {
        setOpen(false);
        setParams(initial);
        var list = params.list.map(function (item) {
            item['is_read'] = false;
            item['is_update'] = false;
            item['is_delete'] = false;
            item['is_write'] = false;
        });
        setParams(__assign(__assign({}, params), { list: list }));
    }
    var onLimitChange = function (event) {
        var value = event.target.value;
        if (value && Number(value) === 0)
            return;
        var re = /^[0-9\b]+$/;
        if ((value && !re.test(value)) || value > totalUserRoles) {
            return;
        }
        setLimitonChange(event.target.value);
    };
    var handleLimit = function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            if (limitonChange > 0) {
                setLimit(limitonChange);
                dispatch(fetchUserRoles(e.target.value, 1, para.searchText));
            }
        }
    };
    // console.log(open, 'uppp');
    useEffect(function () {
        if (isCreateSuccess == true) {
            setParams(initial);
            dispatch(resetApiSuccess());
            dispatch(fetchUserRoles(10, 1, ''));
        }
    }, [isCreateSuccess]);
    // console.log(type, 'userRolesList')
    return (_jsxs("div", { children: [_jsx(Settings, { handleChildChange: handleChildChange, handleRootChange: handleRootChange, params: params, handleChange: handleChange, submit: submit, formErrors: formErrors, setFormErrors: setFormErrors, errors: errors, success: success, addNewRole: addNewRole, successApi: successApi, setParams: setParams, closeEditModal: closeEditModal, openEditModal: openEditModal }), _jsxs("div", __assign({ className: 'mt-5 bg-white flex flex-row justify-between items-center px-6 py-4 rounded-xl dark:bg-[#27282D]' }, { children: [_jsxs("div", __assign({ className: '' }, { children: [_jsx("label", __assign({ htmlFor: 'search', className: 'text-[#AEB0CF] text-xs font-medium' }, { children: "Search.." })), _jsx(SearchBar, { label: 'Search', handleChange: handleSearch, value: para.searchText, name: 'searchText', width: '', bg: 'dark:bg-[#333542]' })] })), _jsxs("div", __assign({ className: 'flex items-center' }, { children: [_jsx("div", { children: _jsx(CustomButton, __assign({ onClick: handleSubmit, disabled: disableBtn }, { children: _jsx("p", { children: "Apply" }) })) }), resetBtn ?
                                _jsx("div", __assign({ className: 'ml-2 cursor-pointer text-[#27282D] dark:text-white', onClick: handleCancel }, { children: _jsx("p", { children: _jsx("u", { children: "Reset" }) }) })) : ''] }))] })), isLoading ? (_jsx("div", __assign({ className: 'w-full h-96 flex justify-center items-center' }, { children: _jsx("span", __assign({ className: 'text-3xl font-bold text-primary dark:text-white' }, { children: "Loading..." })) }))) : (userRolesList === null || userRolesList === void 0 ? void 0 : userRolesList.length) > 0 ? (_jsxs("div", { children: [_jsx(BasicTable, { cols: col, data: userRolesList, handleChildChange: handleChildChange, handleRootChange: handleRootChange, params: params, handleChange: handleChange, submit: submit, formErrors: formErrors, errors: errors, success: success, onRowEdit: onRowEdit, openEditModal: openEditModal, closeEditModal: closeEditModal, open: open, deleteRole: deleteRole }), _jsx(Pagination, { currentPage: currentPage, totalCount: totalUserRoles, pageSize: limit > 0 ? limit : 0, onPageChange: function (page) { return setCurrentPage(page); }, onLimitChange: onLimitChange, handleLimit: handleLimit, limit: limitonChange })] })) : (_jsx("div", __assign({ className: 'flex justify-center items-center flex-col mt-6' }, { children: _jsx("p", __assign({ className: 'text-[18px]' }, { children: "No Results found !!" })) })))] }));
};
export default UserRole;
