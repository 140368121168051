var _a;
import { showToastMessage } from '../../utils/helpers';
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
var initialState = {
    loadA: false,
    loadB: false,
    loadC: false,
    loadD: false,
    apiSuccess: false,
    employeeCount: {},
    cardCount: {},
    designationCount: [],
    departmentCount: [],
};
export var dashboard = createSlice({
    name: 'dashboard',
    initialState: initialState,
    reducers: {
        formReset: function () { },
        creationFailure: function (state, action) {
            state.apiSuccess = false;
            showToastMessage(action.payload.message, 'error');
        },
        ListPendingA: function (state) {
            state.loadA = true;
        },
        ListPendingB: function (state) {
            state.loadB = true;
        },
        ListPendingC: function (state) {
            state.loadC = true;
        },
        ListPendingD: function (state) {
            state.loadD = true;
        },
        totalEmployeeCount: function (state, action) {
            state.employeeCount = action.payload.data;
            state.apiSuccess = true;
            state.loadA = false;
        },
        totalCardCount: function (state, action) {
            state.cardCount = action.payload.data;
            state.apiSuccess = true;
            state.loadB = false;
        },
        designationTotalCount: function (state, action) {
            state.designationCount = action.payload.data;
            state.apiSuccess = true;
            state.loadC = false;
        },
        departmentTotalCount: function (state, action) {
            state.departmentCount = action.payload.data;
            state.apiSuccess = true;
            state.loadD = false;
        },
    },
});
export var ListPendingA = (_a = dashboard.actions, _a.ListPendingA), ListPendingB = _a.ListPendingB, ListPendingC = _a.ListPendingC, ListPendingD = _a.ListPendingD, creationFailure = _a.creationFailure, totalEmployeeCount = _a.totalEmployeeCount, totalCardCount = _a.totalCardCount, designationTotalCount = _a.designationTotalCount, departmentTotalCount = _a.departmentTotalCount;
export default dashboard.reducer;
export var fetchTotalEmployees = function () {
    return apiCallBegan({
        url: "/admin/employee_count",
        method: 'GET',
        onStart: ListPendingA.type,
        onSuccess: totalEmployeeCount.type,
        onError: creationFailure.type,
    });
};
export var fetchCardCount = function (start_Date, end_date) {
    return apiCallBegan({
        url: "/admin/card_count?start_date=".concat(start_Date, "&end_date=").concat(end_date),
        method: 'GET',
        onStart: ListPendingB.type,
        onSuccess: totalCardCount.type,
        onError: creationFailure.type,
    });
};
export var fetchDesignationCount = function (id) {
    return apiCallBegan({
        url: "/admin/employee_count_designation?dept_id=".concat(id),
        method: 'GET',
        onStart: ListPendingC.type,
        onSuccess: designationTotalCount.type,
        onError: creationFailure.type,
    });
};
export var fetchDepartmentCount = function (id) {
    return apiCallBegan({
        url: "/admin/employee_count_department?dept_id=".concat(id),
        method: 'GET',
        onStart: ListPendingD.type,
        onSuccess: departmentTotalCount.type,
        onError: creationFailure.type,
    });
};
